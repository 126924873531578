#fruitTowersIframeContainer {

  border: none;
  margin: 0;
  padding: 0;
}

#apg_wrapper {

  border: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

@media (max-width: 968px) {
  #apg_wrapper {
    height: calc(100vh - 55px) !important;
    width: 100%;
    position: unset !important;
    padding-bottom: 0 !important;
  }

  #fruitTowersIframeContainer {
    height: calc(100vh - 55px);
    width: 100%;
    position: absolute;
    top: 55px;
    left: 0;
    bottom: 0;
    right: 0;
    border: none;
    margin: 0;
    padding: 0;
    overflow: visible;
    /*z-index: 999999;*/
  }
}
