.draw-detail-wrapper > .btnBack {
    position: absolute;
    z-index: 2;
    filter: invert(1);
    padding: 10px;
    right: 0;
}

.draw-detail-wrapper {
    background: var(--bs-white);
    width: 600px;
    max-width: 100%;
    height: 100%;
    /*position: fixed;*/
    /*top: unset;*/
    /*z-index: 1041;*/
    /*bottom: -600px;*/
    /*overflow: hidden;*/
    /*left: 27%;*/
    /*overflow-y: auto;*/
    /*visibility: hidden;*/
    /*transition: all 0.25s;*/
    /*-webkit-transition: all 0.25s;*/
}

.draw-detail-wrapper .body .photos img {
    width: 100%;
    object-fit: contain;
    height: auto;
}

.draw-detail-wrapper .body .photos {
    /*margin-left: -1rem;*/
    /*margin-right: -1rem;*/
    margin-bottom: 1rem;
}

.draw-detail-wrapper .body {
    padding: 0;
    position: relative;
    height: 100%;
}

.draw-detail-wrapper .draw-title {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 0;
    padding-left: 1rem;
}

.draw-detail-wrapper .draw-name {
    color: var(--bs-gray-500);
    padding: 0 1rem;
}

.draw-detail-wrapper .body .timer {
    background: var(--bs-warning-bg-subtle);
    float: right;
    padding: 0.3rem;
    border-radius: var(--bs-border-radius);
    line-height: 15px;
    margin-right: 1rem;
}

.draw-detail-wrapper .body .timer img {
    width: 14px;
    margin-right: 0.5rem;
}

.draw-detail-wrapper .body .timer span {
    font-weight: 700;
    color: var(--bs-warning);
    font-size: 12px;
}

.draw-detail-wrapper .body .desciption ul li {
    margin: 15px 0px;
}

.draw-detail-wrapper .body .desciption ul {
    margin: 0;
    padding: 0;
    /*list-style: none;*/
}

.draw-detail-wrapper .body .desciption strong {
    font-size: 1rem;
}

.draw-detail-wrapper .body .desciption {
    /* background: #d6e1ea; */
    /*margin: -1rem;*/
    padding: 1.5rem;
    font-size: 0.9rem;
    /* border-radius: var(--bs-border-radius-xxl) var(--bs-border-radius-xxl) 0px 0px; */
}

.draw-detail-wrapper .body .footer .cutEffect {
    margin-left: 0;
    margin-right: 0;
}

.draw-detail-wrapper .body .footer .row {
    margin-left: 0;
    margin-right: 0;
}

.draw-detail-wrapper .body .desciption p {
    margin: 0 !important;
}

.draw-detail-wrapper .body .footer .add {
    display: flex;
    overflow: hidden;
    min-width: 100%;
    height: 100%;
    justify-content: center;
}

.draw-detail-wrapper .body .footer .add .input i {
    border: 2px solid white;
    border-radius: 50%;
    width: 24px;
    min-width: 24px;
    height: 24px;
    font-weight: 500;
    text-align: center;
    line-height: 18px;
    color: white;
    font-size: 18px;
    font-style: normal;
    cursor: pointer;

    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.draw-detail-wrapper .body .footer .add .input input:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
}

.draw-detail-wrapper .body .footer .add .input span {
    color: white;
    font-weight: 700;
    display: block;
    font-size: 14px;
    line-height: 20px;
    margin-top: 4px;
    margin-right: 10px;
    width: 80%;
    text-align: center;
}

.draw-detail-wrapper .body .footer .add .input input {
    width: inherit;
    background: transparent;
    color: white;
    font-weight: 700;
    border: 0;
    text-align: center;
    font-size: 14px;
}

.draw-detail-wrapper .body .footer .add .input {
    display: flex;
    background: #646BDD;
    border-radius: var(--bs-border-radius-pill);
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    max-height: 38px;
    height: 38px;
    border-color: #646BDD;
    align-items: center;
    width: 100px;
}

.draw-detail-wrapper .body .footer .add > .price {
    background: #646BDD;
    border-radius: var(--bs-border-radius-pill);
    padding: 0.5rem 0.875rem;
    padding-left: 0;
    color: var(--bs-white);
    font-weight: 700;
    font-size: 14px;
    display: block;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.draw-detail-wrapper .body .footer .detail {
    padding: 1rem;
}

.draw-detail-wrapper .body .footer .detail div {
    font-size: 14px;
    color: var(--bs-gray-500);
    font-weight: 600;
}

.draw-detail-wrapper .body .footer {
    border-top: 1px solid;
    background: var(--bs-white);
    height: 85px;
    position: sticky;
    width: auto;
    bottom: 0;
    /*margin-bottom: -1rem;*/
    /*margin-left: -1rem;*/
    /*margin-right: -1rem;*/
}

.draw-detail-wrapper .btn-add-to-cart span {
    float: right;
}

.draw-detail-wrapper .btn-add-to-cart {
    display: flex;
    font-weight: 700;
    height: 38px;
    background-color: #8389E4 !important;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
}

.draw-detail-wrapper .body .flags {
    height: 22px;
    padding: 0 0.5rem;
}

.draw-detail-wrapper .body .flags span {
    margin-right: 2px;
    margin-left: 2px;
    font-size: 12px;
}

.draw-detail-modal {
    padding: 0 !important;
    width: 600px !important;
}

.product-foot {
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}

.basket-btn {
    height: 38px;
    width: 110px;
    border-radius: 20px;
    border: none;

}
