.scratch-card .body .desciption h2 {
  font-size: 56px;
  color: var(--bs-primary);
  font-family: "Dela Gothic One", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.scratch-card .body .desciption h3 {
  font-size: 24px;
  color: var(--bs-primary);
  font-family: "Dela Gothic One", sans-serif;
  font-weight: 800;
  font-style: normal;
  margin-top: 10px;
}

.scratch-card .body .info {
}
.scratch-card .body .quantities {
  background: var(--bs-white);
  padding: 0.5rem;
  margin-left: -1rem !important;
  margin-right: -1rem !important;
  margin-top: 1.5rem !important;
  /*border-radius: 4px;*/
}

@media (max-width: 576px) {
  .scratch-card .body .quantities {
    margin-top: 0 !important;
  }
}

.scratch-card .body .desciption {
  height: 120px;
  text-align: center;
  font-weight: 600;
}
.scratch-card .body {
  background-image: url(/public/assets/scratch_card_bg.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 0px 0.7rem;
  padding: 2rem 1rem 0rem 1rem;
  margin-bottom: 1rem;
  border-radius: var(--bs-border-radius-xl);
  overflow: hidden;
}

.ScratchCard__Canvas {
  width: 100%;
  height: 100%;
}

.scratch-card {
  background-color: var(--bs-white);
  border-radius: var(--bs-border-radius-xl);
  overflow: hidden;
  border: 0.5px solid var(--bs-primary);
}

.scratch-card .head {
  padding: 1rem;
  font-size: 13px;
}
.scratch-card .head h2 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
}

.scratch-card .foot {
  position: relative;
  padding: 0.5rem;

  background: var(--bs-white);

  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-size: 0px 100%, 100% 1.5px, 0px 100%, 100% 0px;
  background-repeat: no-repeat;

  background-image: repeating-linear-gradient(
      0deg,
      var(--bs-gray-400),
      var(--bs-gray-400) 5px,
      transparent 5px,
      transparent 10px
    ),
    repeating-linear-gradient(90deg, var(--bs-gray-400), var(--bs-gray-400) 5px, transparent 5px, transparent 10px),
    repeating-linear-gradient(180deg, var(--bs-gray-400), var(--bs-gray-400) 5px, transparent 5px, transparent 10px),
    repeating-linear-gradient(270deg, var(--bs-gray-400), var(--bs-gray-400) 5px, transparent 5px, transparent 10px);
  -o-border-image: repeating-linear-gradient(
    0deg,
    var(--bs-gray-400),
    var(--bs-gray-400) 5px,
    transparent 5px,
    transparent 10px
  );
  border-image: repeating-linear-gradient(
    0deg,
    var(--bs-gray-400),
    var(--bs-gray-400) 5px,
    transparent 5px,
    transparent 10px
  );
}

.scratch-card .foot:after {
  content: "";
  position: absolute;
  width: 10px;
  background: var(--ps-body-bg);
  height: 20px;
  border-radius: 10px 0px 0px 10px;
  right: 0px;
  top: -10px;
}
.scratch-card .foot:before {
  content: "";
  position: absolute;
  width: 10px;
  background: var(--ps-body-bg);
  height: 20px;
  border-radius: 0px 10px 10px 0px;
  left: 0px;
  top: -10px;
}

.scratch-card.winner .foot:before {
  background: #ffffff;
}
.scratch-card.winner .foot:after {
  background: #ffffff;
}

.scratch-card-modal h2 {
  color: var(--bs-white);
}
.scratch-card-modal {
  background-image: url(/public/assets/scratch_bg.png) !important;
  background-size: calc(100% + 40px) !important;
  background-position: -20px !important;

  position: absolute;
  inset: 40px;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  margin-left: calc(50% - 275px);
  width: 415px;
  height: 541px;
}

.swiper-wrapper {
  transition-property: all !important;
}

#card-slider .swiper-button-next,
#card-slider .swiper-button-prev {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  width: 42px !important;
}
.ScratchCard__Container {
  border-radius: 20px;
  overflow: hidden;
}
.scratch-off-card > .row > .col-6.lost {
  color: var(--bs-danger);
  -webkit-text-stroke: 0px var(--bs-danger);
}
.scratch-off-card > .row > .col-6.won {
  color: var(--bs-success);
  -webkit-text-stroke: 0px var(--bs-success);
}
.scratch-off-card > .row > .col-6.semi-won {
  color: var(--bs-warning);
  -webkit-text-stroke: 0px var(--bs-warning);
}
.scratch-off-card > .row > .col-6 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.scratch-off-card > .row {
  position: relative;
  text-align: center;
  font-weight: 900;
  font-size: 26px;
  -webkit-text-stroke: 0px var(--bs-dark);
}
.scratch-off-card {
  position: relative;
}
.scratch-off-card img {
  width: 100%;
  position: absolute;
  z-index: 0;
}

.scratch-card-info {
  border-radius: 10px;
  overflow: hidden;
  background: var(--ps-body-bg);
}
.scratch-card-info thead th {
  background: var(--bs-primary);
  color: #ffffff;
}

.scratch-card-info tbody > tr:nth-of-type(even) > * {
  padding: 0 !important;
}
.scratch-card-info tbody > tr:nth-of-type(even) > td > .ms-2 {
  border-radius: 5px 0px 0px 5px;
}
.scratch-card-info tbody > tr:nth-of-type(even) > td > .me-2 {
  border-radius: 0px 5px 5px 0px;
}
.scratch-card-info tbody > tr:nth-of-type(even) > td > * {
  background-color: #ffffff;
  padding: 0.5rem 0.5rem;
}

.btn-outline-quantity {
  /*color: #FFFFFF;*/
  border: 1px solid var(--bs-primary) !important;
  border-radius: var(--bs-btn-border-radius);
  color: var(--bs-primary) !important;
}

.btn-outline-quantity.active {
  background-color: var(--bs-primary) !important;
  color: #ffffff !important;
}

.game-win-card {
  background-image: url(/public/assets/scratch_card_bg.png);
}

.scratch-card .foot > p {
  font-size: 12px;
  margin-bottom: 0;
  color: var(--bs-primary);
}

.won-ticket-row {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 10px;
  padding: 0.5rem;
  color: #000000;
}
.won-ticket-row:nth-of-type(odd) {
  display: flex;
  justify-content: space-between;
  background: var(--bs-gray-500);
  border-radius: 10px;
  padding: 0.5rem;
  color: #ffffff;
}

.won-card-header {
  color: var(--bs-white) !important;
}

.won-tickets-area {
  height: 115px;
  overflow: scroll;
}

.font-29 {
  font-size: 29px !important;
}
