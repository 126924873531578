
.agreement-page > div p{
    margin: 0;
}
.agreement-page h1{
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 1rem;
}


