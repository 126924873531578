.games-widgets {
  background-color: #ffffff;
}

.games-widgets a {
  text-decoration: none;
}

.games-widgets .item span {
  font-size: var(--font-size);
  font-weight: 600;
  color: #1e3d98;
  /* border-bottom: 1px solid #646BDD60; */
  /* padding-bottom: 10px; */
  /* letter-spacing: -0.5px; */
}
.games-widgets .item .badge {
  font-size: 8px;
  color: #ffffff;
  position: absolute;
  top: 3px;
  right: 3px;
  /* border-bottom: 1px solid #646BDD60; */
  /* padding-bottom: 10px; */
  letter-spacing: -0.5px;
}

.games-widgets .item {
  position: relative;
  border-radius: var(--bs-border-radius);
  text-align: left;
  padding: 10px;
  background: #d0d3f545;
  text-align: center;
}
.games-widgets .item.active {
  background: #d0d3f5;
}
.games-widgets .item:not(.active):hover {
  background: #d0d3f5;
}

.games-widgets .swiper-button-prev,
.games-widgets .swiper-button-next {
  height: 100%;
  background: #9ca0e9;
  color: #ffffff;
  font-weight: bold;
  width: 25px;
  top: 0;
  margin-top: 0;
}
.games-widgets .swiper-button-prev {
  border-radius: var(--bs-border-radius-xl) 0 0 var(--bs-border-radius-xl);
  left: 0;
}
.games-widgets .swiper-button-next {
  border-radius: 0 var(--bs-border-radius-xl) var(--bs-border-radius-xl) 0;
  right: 0;
}
.games-widgets .swiper-button-prev:after {
  content: "prev";
  font-size: 16px;
}
.games-widgets .swiper-button-next:after {
  content: "next";
  font-size: 16px;
}
