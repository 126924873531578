.raffle-item {
  width: 100%;
}

.nav-tabs .raffle-item.active .nav-link {
  width: 100%;
  text-align: center;
}

.nav-tabs .raffle-item:last-child.active .right {
  display: none;
}

.raffle-cart {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: #8389e4;
  padding: 1rem;
  row-gap: 0.5rem;
  height: 450px;
}

.raffle-mobile-cart {
  padding: 0 !important;
  height: 100%;
  border-radius: 0 !important;
  background: transparent;
}

.raffle-cart .cart-items-wrapper {
  display: flex;
  flex-direction: column;
  height: 280px;
  overflow-y: scroll;
  gap: 0.5rem;
}

.raffle-mobile-cart .cart-items-wrapper {
  height: 75vh;
}

.raffle-cart > h3 {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #ffffff;
  margin-bottom: 10px;
}

.cart-item {
  display: flex;
  border-radius: 5px;
  background: #ffffff;
  padding: 0.5rem;
}

.add {
  height: 32px;
  display: flex;
}

.add .input i {
  border: 2px solid #646bdd;
  border-radius: 50%;
  width: 18px;
  min-width: 18px;
  height: 18px;
  font-weight: 500;
  text-align: center;
  line-height: 12px;
  color: #646bdd;
  font-size: 14px;
  font-style: normal;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.add .input input:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}

.add .input span {
  font-weight: 700;
  display: block;
  font-size: 14px;
  line-height: 20px;
  margin-top: 4px;
  margin-right: 10px;
  width: 80%;
  text-align: center;
}

.add .input input {
  width: inherit;
  color: #646bdd;
  font-weight: 700;
  border: 0;
  text-align: center;
  font-size: 14px;
}

.add .input {
  display: flex;
  border-radius: var(--bs-border-radius-pill);
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  max-height: 32px;
  align-items: center;
  width: 100px;
  background-color: #fff;
}

.add .input.mobile-item-input {
  border-radius: 5px;
  border-color: #6f76e6;
  background: #adb2ff;
  max-height: 45px;
  height: 45px;
}

.add .input.mobile-item-input i.left {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: 0;
}

.add .input.mobile-item-input i.right {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: 0;
}

.add .input.mobile-item-input i {
  border: 1px solid;

  width: 40px;
  height: 33px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}

.add .input.mobile-item-input input {
  width: auto;
  border: 1px solid;

  height: 33px;
  margin: 10px 0px;
}

.add .trash {
  display: flex;
  background-color: #fff;
  border-right: 1px solid #646bdd;
  border-top: 1px solid #646bdd;
  border-bottom: 1px solid #646bdd;
  border-radius: var(--bs-border-radius-pill);
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

.add .trash.mobile-item-total {
  background-color: #6f76e6;
  border-right: 1px solid #6f76e6;
  border-top: 1px solid #6f76e6;
  border-bottom: 1px solid #6f76e6;
  border-radius: 5px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  line-height: 18.89px;
  letter-spacing: 0.07038461416959763px;
  text-align: left;
}

.add .trash > img {
  padding: 0.4rem;
  border-radius: var(--bs-border-radius-pill);
  border-color: #cc0000;
  width: 24px;
  height: 24px;
  background-color: #cc0000;
}

.cart-item .raffle-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.raffle-mobile-cart .cart-item .raffle-image {
  height: auto;
}

.cart-item h4 {
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #1e3d98;
  margin-bottom: 0;
}

.cart-item-total {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #646bdd;
}

.result-header {
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.02em;
  text-align: right;
  color: #1e3d98;
  margin-bottom: 0;
}

.draw-item {
  border-radius: 0.5rem;
  overflow: hidden;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  background-color: #fff;
  /* border:0.5px solid; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.draw-image {
  width: 100%;
  border-radius: 0.5rem;
}

.status {
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.bg-warning {
  background-color: #f0ad4e;
}

.bg-success {
  background-color: #5cb85c;
}

.bg-danger {
  background-color: #d9534f;
}

.link-underline-primary {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

.link-underline-primary:hover {
  color: #0056b3;
  text-decoration: underline;
}

.draw-filter-wrapper {
  border-radius: var(--bs-border-radius);
  background: var(--bs-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 992px) {
  .draw-filter-wrapper > div {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    width: 100%;
  }
}

.draw-filter-wrapper-column {
  border-radius: var(--bs-border-radius-xl);
  background: var(--bs-white);
  padding: 1rem;
  margin-bottom: 0.5rem;
  /*width: calc(100% - 1rem) !important;*/
  display: flex;
  flex-direction: column;
}

.draw-filter-wrapper button svg {
  margin-top: -2px;
  margin-right: 5px;
}

.draw-filter-wrapper > .float-start > h4 > span {
  font-weight: 400;
  font-size: 0.8rem;
  color: var(--bs-gray-500);
  display: block;
}

.draw-filter-wrapper > .float-start > h4 {
  font-weight: 700;
  font-size: 14px;
  margin: 0;
}

.draw-filter-wrapper-column > h4 {
  font-weight: 700;
  font-size: 1rem;
  margin: 0;
}

.mobile-tab-widget {
  padding: 10px;
  border-radius: 10px;
  background: #ffffff;
  position: relative;
}

.border-bottom-dotted:after {
  content: "";
  position: absolute;
  width: 10px;
  background: var(--bs-body-bg);
  height: 20px;
  border-radius: 10px 0px 0px 10px;
  right: 0px;
  top: 51%;
  border: none;
}

.border-bottom-dotted:before {
  content: "";
  position: absolute;
  width: 10px;
  background: var(--bs-body-bg);
  height: 20px;
  border-radius: 0px 10px 10px 0px;
  left: 0px;
  top: 51%;
  border: none;
}

.raffle-tab-item {
  background: var(--bs-white);
  padding: 10px;
  margin: 0 15px;
  border-radius: var(--bs-border-radius-xl);
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: relative;
}

.raffle-tab-item > img {
  height: 35px;
  /*opacity: 0.5;*/
  margin-right: 0.25rem;
}

.raffle-tab-item.selected {
  background-color: #bec8f7;
  padding: 10px;
  margin: 0 15px;
  border-radius: var(--bs-border-radius-xl);
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: relative;
}

.raffle-tab-item > img {
  height: 35px;
  /*opacity: 0.5;*/
  margin-right: 0.25rem;
}

.raffle-tab-item h2 {
  margin-top: 0.5rem;
  color: #6f76e6;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0;
  text-align: center;
}

.raffle-tab-item-badge {
  position: absolute;
  right: -7.5%;
  background: #ff3a3a;
  border-radius: 12px;
  padding: 0 5px;
  font-size: 11px;
  color: #ffffff;
  font-weight: 700;
}

.raffle-tab-item.border {
  height: 70px;
  margin: 0;
}

.border-bottom-dotted {
  margin: 1rem 0;
  border-bottom: 1px dotted #646cdda6;
}

.draw-tab-item {
  text-align: center;
  color: #1e3d98;
  font-weight: 500;
  font-size: 12px;
  border-radius: 10px;
  padding: 10px 0px;
  border: 1px solid #ffffff;
}

.draw-tab-item.selected {
  border: 1px solid #adb2ff;
  background: #adb2ff47;
}

.mobile-raffle-cart.sticky {
  position: fixed;
  /*top: calc(100vh - 30vh);*/
  bottom: 45px;
  z-index: 1;
  width: 98%;
  left: 1%;
}

.mobile-raffle-cart h2 {
  padding: 0;
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
}

.mobile-raffle-cart {
  background: var(--bs-warning);
  height: 120px;
  width: 100%;
  border-radius: var(--bs-border-radius-lg);
  padding: 0.5rem;
}

.mobile-raffle-cart-total {
  background: #ffffff;
  padding: 0.75rem 0.25rem;
  border-radius: 10px;
}

.mobile-raffle-cart-total .col-3 {
  font-size: 12px;
}

.mobile-raffle-cart-total .col-4 {
  font-size: 12px;
}

.basket-summary-info {
  display: flex;
  gap: 5px;
  padding: 5px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  min-width: 285px;
}

.basket-summary-info-left {
  border-radius: 5px;
  background: #adb2ff;
  width: 70px;
  height: 70px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
}

.alert-link {
  font-weight: 700;
  width: 100%;
  border-radius: 5px;
  background: #adb2ff;
  color: #ffffff;
  height: 70px;
}

.basket-summary-info-btn {
  background-color: #adb2ff;
  text-align: center;
  font-size: 9px;
  font-style: normal;
  font-weight: 700;
  color: #fff;
}

.order-steps {
  position: relative;
  width: 100%;
}

.order-steps .body {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0;
  background-color: #1f3d97;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
  transition: height 0.3s ease;
}

.order-steps.open .body {
  height: 200px;
}

.order-steps img {
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
}

.order-steps .btn {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
