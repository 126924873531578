.bank-card {
    display: flex;
    flex-direction: row;
    background-color: #E2E3F9;
    border-radius: var(--bs-border-radius);
    padding: 1rem;
    align-items: center;
    justify-content: space-between;
    transition: background-color 0.3s ease;
}

.bank-card:hover, .bank-card.active {
    background-color: #FFCA56;
    font-weight: bold;
}

.btn-purple {
    background-color: var(--bs-body-bg);
    color: #FFCA56;
    /*opacity: 0;*/
}

.bank-card:hover .btn-purple, .btn-purple.active {
    opacity: 1;
}

.sss {
    /*display: flex;*/
    /*flex-direction: column;*/
    /*gap: 1rem;*/
}
.sss-questions {
    /*display: flex;*/
    /*flex-direction: row;*/
    margin-bottom: 1rem;
}

.sss-questions > div > p {
    color: gray ;
    font-weight: bold;
    text-wrap: pretty;
}

.sss-questions > div > span {
    color: gray ;
}


.selected-bank{
    border: 1px solid;
    border-radius: 10px;
    padding: 1rem;
    margin: 0;
}
