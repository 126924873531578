.login-form{
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: var(--bs-border-radius-xl);
    background: #FFFFFF;
    width: 400px;
    max-width: calc(100% - 20px);
    z-index: 3;
    position: fixed;

    top: 50vh;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.btn-close {
    align-self: flex-end;
    text-decoration: none;
}

.form {
    display: flex;
    flex-direction: column;
}


.eye-icon{
    float: right;
    margin-right: 6px;
    margin-top: -13px;
    position: relative;
    z-index: 2;
}


.forget-password-link {
    cursor: pointer;
    text-decoration: underline;
    width: 100%;
    text-align: center;
    margin-top: 1rem;
    font-weight: 400;
    font-size: 14px;
    color: #1E3D98;
}

