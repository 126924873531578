.footer-cookies-bar{
    min-height: 25px;
    background-color: #e7e7e7;
    color: #95959b!important;
    line-height: 16px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    font-weight: 400;
    font-family: roboto,sans-serif;
    font-size: 14px;
}
.footer-cookies-bar .footer-cookies-container {
    padding:10px;
    position: relative;
}
.footer-cookies-bar .footer-cookies-container a {
    color: #fff;
    text-decoration: underline;
}
.footer-cookies-bar .footer-cookies-container button {
  margin-left:10px;
}
