.withdrawable-widget {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    padding-inline: 4rem;
    justify-content: space-between;
    background-color: rgb(156, 160, 233, 0.3);
    border-radius: var(--bs-border-radius);
}

.withdrawable-widget > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.withdraw-form {
    margin-top: 1rem;
    padding: 1rem;
}

.sure-box {
    align-items: center;
}

.checkbox {
    display: flex;
    align-items: center;
}

.withdraw-widget{
    background-color: rgb(156, 160, 233, 0.3);
    border-radius: var(--bs-border-radius);
    padding: 1rem;
}
