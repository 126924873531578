.iframeContainer-statistics {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.iframeContainer-statistics iframe {
  width: 100%;
  height: 100%;
  border: none;
  overflow: hidden;
}
