#gameIFrame {
  width: 100% !important;
  height: 100% !important;
  border: none;
  text-align: center;
  background-color: Black;
}

.iframeContainer {
  border: none;
  margin: 0;
  padding: 0;
  width: 100%;
}


.iframeContainer-zeplin {
  min-height: calc(100vh);
  border: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

@media (max-width: 968px) {
  .iframeContainer-zeplin {
    height: calc(100vh - 50px);
    min-height: calc(100vh - 50px);
    width: 100%;
    /*position: absolute;*/
    top: 55px;
    left: 0;
    bottom: 0;
    right: 0;
    border: none;
    margin: 0;
    padding: 0;
    overflow: visible;
    z-index: 10;
  }
}
