header{
    width: 100%;
    display: flex;
    /*position: absolute;*/
    z-index: 2;
    background: #2f3da8;
}

header nav > a.active{
    text-shadow: 0 0 8px #FFFFFF60;
    background: #152A6E;
    font-weight: 600;
    color: #FFFFFF;
}
header nav > a:hover{
    background: #5160c5;
}
header nav > a{
    background: #2f3da8;
    display: block;
    float: left;
    padding: 13px 5px 13px 5px;
    color: #FFFFFF;
    text-decoration: none;
    transition: 0.3s all;
    font-size: 16px;
    font-weight: 600;

}
header nav > a:not(:last-child){
    /* border-right: 1px solid rgba(255, 255, 255, 0.25); */
}


@media screen and (max-width: 1380px) {
    header nav > a{
        background: #2f3da8;
        display: block;
        float: left;
        padding: 15px 9px 15px 9px;
        color: #FFFFFF;
        text-decoration: none;
        transition: 0.3s all;
        font-size: 13px;
        font-weight: 600;
    }

}

@media screen and (max-width: 986px) {
    header nav > a{
        background: #2f3da8;
        display: block;
        float: left;
        padding: 15px 5px 15px 5px;
        color: #FFFFFF;
        text-decoration: none;
        transition: 0.3s all;
        font-size: 12px;
        letter-spacing: -0.5px;
        font-weight: 600;
    }
    header{

        background: transparent;
    }
}


@media screen and (max-width: 1239px) {


    header nav > a{
        background: #2f3da8;
        display: block;
        float: left;
        padding: 15px 5px 15px 5px;
        color: #FFFFFF;
        text-decoration: none;
        transition: 0.3s all;
        font-size: 13px;
        font-weight: 500;
    }

}



header nav > a.login{
    background: #FFCA56;
    color: #1E3D98;
    margin-right: 1px;
}
header nav > a.register{
    font-weight: 600;
    background: #FFCA56;

    color: #1E3D98;
}

header nav > a.login:hover , header nav > a.register:hover{
    background: #ce970b;
}

header .user-profile span{
    display: block;
    margin-right: 35px;
}
header .user-profile span.user-name{
    width: 40px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
header .user-profile{
    float: left;
    background: var(--bs-warning);
    height: 50px;
    padding: 5px 10px;
    width: 125px;
}

header .user-balance .lines span{display: block; line-height: 15px; }
header .user-balance .lines{
    font-weight: 600;
    width: 100%;
    min-width: 60px;
    margin-top: 2px;
}
header .user-balance label{
    font-size: 12px;
}
header .user-notification{
    float: left;
    background: var(--bs-warning);
    height: 50px;
    margin-right: 1px;
    padding: 15px 10px 0 10px;
    line-height: 13px;
}
header .user-tickets{
    float: left;
    background: var(--bs-warning);
    height: 50px;
    margin-right: 1px;
    padding: 0 10px 0 10px;
    line-height: 50px;
    font-weight: bold;
    cursor: pointer;
}
header .user-balance{
    float: left;
    background: var(--bs-warning);
    height: 50px;
    margin-right: 1px;
    padding: 4px 10px;
    line-height: 13px;
}


header .member-dropdown-menu{
    /*display: none;*/
    position: absolute;
    width: 200px;
    background: var(--bs-white);
    right: 0;
    top: 50px;
    list-style: none;
    margin: 0;
    padding: 0 0 0 0;
    z-index: 2;
}

header .member-dropdown-menu li:not(:last-child){
    border-bottom: 1px solid #1E3D9820;
}
header .member-dropdown-menu li{
    padding: 5px 0;
}
header .member-dropdown-menu li:hover a{
    background: #D0D3F5;
}
header .member-dropdown-menu li a{
    /*display: block;*/
    padding: 5px 5px;
    border-radius: var(--bs-border-radius);
    margin-left: 5px;
    margin-right: 5px;
    text-decoration: none;
    color: #1E3D98;
    display: flex;
    align-items: center;
    font-size: 13px;
}
header .member-dropdown-menu li a img{
    margin-right: 15px;
    width: 18px;
    filter: invert(8%) sepia(90%) saturate(4287%) hue-rotate(260deg) brightness(115%) contrast(120%);
}

/*header .user-balance:hover .member-dropdown-menu{*/
/*    display: block;*/
/*}*/
/*header .user-profile:hover .member-dropdown-menu{*/
/*    display: block;*/
/*}*/

.nav-logo{
    height: 40px;
    margin-top: 5px;
    margin-left: 10px;
}


footer .nav-logo{
    margin-left: 0;
}

