.member-menu{
    list-style: none;
    margin: 0;
    padding: 0;
    border-radius: var(--bs-border-radius-lg);
    overflow: hidden;
}

.member-menu li.active a{
    background: #D0D3F5;
}
.member-menu li:not(:first-child) a{
    /*display: block;*/
    padding: 15px 10px;
    border-radius: var(--bs-border-radius);
    margin-left: 10px;
    margin-right: 10px;
    text-decoration: none;
    color: #1E3D98;
    display: flex;
    align-items: center;
}
.member-menu li:not(:first-child) a img{
    margin-right: 15px;
    filter: invert(8%) sepia(90%) saturate(4287%) hue-rotate(260deg) brightness(115%) contrast(120%);
}

.member-menu li:first-child{
    background: var(--bs-warning);
    padding: 10px;
    margin-bottom: 10px;
}

.member-form {
    padding: 1rem;
}

.member-form label {
    font-weight: 600;
    margin-right: 1.5rem;
    /*width: calc(100% - 2rem);*/
}

.form-check-label{
    width: calc(100% - 2rem);
}

.filter-form {
    display: flex;
    gap: 10px;
    padding-bottom: 10px;
}

.member-menu-bottom {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding-top: 1rem;
    position: absolute;
}


@media (max-width: 968px) {
    .member-wrapper{
        margin-top: 1rem;
        padding: 0 !important;

        /*margin-top: 1rem;*/
        /*padding: 0 !important;*/
        background: transparent !important;
        box-shadow: none !important;

    }
    .container > .member-wrapper {
        margin-left: calc(calc(var(--bs-gutter-x) * .5) * -1) !important;
        margin-right: calc(calc(var(--bs-gutter-x) * .5) * -1) !important;
    }
}

.break-space{
    white-space: break-spaces !important;
}


.notification-item{

    border-radius: 10px;
    background: #E2E3F9;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
