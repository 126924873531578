.hippodrome-widgets {
    background: #FFFFFF;
    border-radius: var(--bs-border-radius-xl);
    height: max-content;
    padding: 10px 35px;


    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    margin-top: 1rem;
    margin-bottom: 1rem;
    position: relative;
    z-index: 1;
}

.hippodrome-widgets a {
    text-decoration: none;
}

.hippodrome-widgets .item h2 span {
    font-size: var(--font-size-sm);
    font-weight: normal;
    display: block;
}

.hippodrome-widgets .item h2 {
    font-size: var(--font-size);
    font-weight: 600;
    color: #1E3D98;
    border-bottom: 1px solid #646BDD60;
    padding-bottom: 10px;
    letter-spacing: -0.5px;
}

.hippodrome-widgets .item .description > div > span {
    display: block;
}

.hippodrome-widgets .item .description > div {
    width: 50%;
}

.hippodrome-widgets .item .description {
    display: flex;
    font-size: 13px;
}

.hippodrome-widgets .item .timeline > div {
    width: 75%;
    height: 5px;
    background: #646BDD75;
    border-radius: var(--bs-border-radius-lg);
}

.hippodrome-widgets .item .timeline {
    background: #FFFFFF;
    border-radius: var(--bs-border-radius-lg);
    border: 1px solid #646BDD75;
    height: 11px;
    padding: 2px;
}

.hippodrome-widgets .item {
    border-radius: var(--bs-border-radius);
    text-align: left;
    padding: 10px;
    background: #D0D3F545;
}

.hippodrome-widgets .item.active {
    background: #D0D3F5;
}

.hippodrome-widgets .item:not(.active):hover {
    background: #D0D3F5;
}


.hippodrome-widgets .swiper-button-prev, .hippodrome-widgets .swiper-button-next {
    height: 100%;
    background: #9CA0E9;
    color: #FFFFFF;
    font-weight: bold;
    margin-top: 0;
    top: 0;
    width: 25px;
}

.hippodrome-widgets .swiper-button-prev {
    border-radius: var(--bs-border-radius-xl) 0 0 var(--bs-border-radius-xl);
    left: 0;
}

.hippodrome-widgets .swiper-button-next {
    border-radius: 0 var(--bs-border-radius-xl) var(--bs-border-radius-xl) 0;
    right: 0;
}

.hippodrome-widgets .swiper-button-prev:after {
    content: 'prev';
    font-size: 16px;
}

.hippodrome-widgets .swiper-button-next:after {
    content: 'next';
    font-size: 16px;
}

.hippodrome-widgets .skeleton .description > div > span {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s skeleton-shine linear infinite;
    height: 20px;
    margin-right: 5px;
    margin-bottom: 3px;
}

.hippodrome-widgets .skeleton h2 {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s skeleton-shine linear infinite;
    height: 45px;
    border: 0 !important;
}


@media (max-width: 968px) {
    .hippodrome-widgets {
        margin-bottom: 0.25rem;
    }
}
