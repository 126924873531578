footer {
  background: #1e3d98;
  color: #bfc1e8;
  padding: 2rem 0;
  margin-top: 2rem;
}
footer h3 {
  font-size: var(--font-size-md);
  margin-bottom: 0.75rem;
  color: var(--bs-white);
}
footer .social-media a img {
  filter: invert(1);
}
footer .social-media a {
  display: inline-block;
  width: 100%;
}

footer .social-media,
footer .mobile-download-links {
  display: flex;
  justify-content: center;
}

footer .mobile-download-links img {
  width: 26px;
  /*filter: invert(1);*/
  float: left;
  margin-right: 10px;
}
footer .mobile-download-links a span {
  font-weight: 700;
}
footer .mobile-download-links a {
  display: flex;
  color: white;
  /*border: 1px solid rgba(255,255,255,.3);*/
  /*border-radius: var(--bs-border-radius);*/
  font-size: 11px;
  text-decoration: none;
  padding: 5px 10px;
  margin-right: 15px;
  line-height: 14px;
  align-items: center;
}

footer ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
footer ul li a {
  color: #bfc1e8;
  text-decoration: none;
  display: block;
}
footer ul li {
}

footer .footer-slogan {
  font-size: 14px;
}

.mobile-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  box-shadow: 0 0 20px #1e3d9850;
  z-index: 1;
}

.mobile-footer a img {
  display: block;
  margin: 0 auto;
  width: 18px;
  max-height: 18px;
  margin-bottom: 5px;
}
.mobile-footer a:nth-child(3) {
  padding-top: 5px;
}
.mobile-footer a:nth-child(3) img {
  width: 28px;
  max-height: 28px;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-bottom: 0;
}
.mobile-footer a {
  background: #ffffff;
  text-align: center;
  display: block;
  padding: 5px 0;
  padding-top: 15px;
  padding-bottom: 5px;

  text-decoration: none;
  font-size: 10px;
  font-weight: 500;
  color: #1e3d98;
  width: 100%;
}

.footer-center {
  background: var(--bs-warning);
  padding: 10px;
  position: absolute;
  top: -10px;
  left: 44%;
  -ms-transform: rotate(315deg);
  -webkit-transform: rotate(315deg);
  transform: rotate(315deg);
  border-radius: 10px;
}

.mobile-footer-accordion-item {
  background: transparent;
  border-radius: 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  padding: 0.5rem 1rem;
}

.accordion-footer-menu {
  background: transparent;
  border-radius: 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
  padding: 0 1rem 0.5rem 1rem;
}
.accordion-footer-menu > ul > li {
  padding: 0.5rem 1rem;
}

@media (max-width: 576px) {
  footer .social-media,
  footer .mobile-download-links {
    font-size: 9px !important;
  }
  footer .mobile-download-links img {
    width: 18px !important;
  }
  footer p {
    font-size: 9px !important;
  }
  footer p > a {
    font-size: 9px !important;
  }
  footer .footer-slogan {
    font-size: 9px;
  }
}
