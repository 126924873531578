.my-raffle-order-item {
  background: var(--ps-body-bg) !important;
}
.my-raffle-order-item .header {
  background: var(--bs-white);
  border-bottom: 0px solid var(--bs-success);
  padding-bottom: 1rem !important;
  text-align: center;

  --mask: radial-gradient(
        5.59px at 50% calc(100% - 7.5px),
        #000 99%,
        #0000 101%
      )
      calc(50% - 10px) 0/20px 100%,
    radial-gradient(5.59px at 50% calc(100% + 2.5px), #0000 99%, #000 101%) 50%
      calc(100% - 5px) / 20px 100% repeat-x;
  -webkit-mask: var(--mask);
  mask: var(--mask);
}

.my-raffle-order-item .item {
  border-radius: 0.5rem;
  overflow: hidden;
  /*padding: 0.5rem;*/
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  background-color: #fff;
  /* border:0.5px solid; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.my-raffle-order-item .item .col-auto {
  background: var(--bs-white);
  padding: 10px !important;
  width: 200px !important;
  position: relative;
  display: flex;
}
.my-raffle-order-item .item .col-auto:after {
  content: "";
  position: absolute;
  width: 20px;
  background: var(--ps-body-bg);
  height: 10px;
  border-radius: 10px 10px 0px 0px;
  right: -10px;
  bottom: 0px;
  z-index: 1;
}
.my-raffle-order-item .item .col-auto:before {
  content: "";
  position: absolute;
  width: 20px;
  background: var(--ps-body-bg);
  height: 10px;
  border-radius: 0px 0px 10px 10px;
  right: -10px;
  top: 0px;
  z-index: 1;
}

.my-raffle-order-item .item .col-8 {
  background: var(--bs-white);
  padding: 1rem;
  /*width: calc(100% - 120px);*/
}

.my-raffle-order-item .item > .col-4 {
  background: var(--bs-white);

  /*width: calc(calc(100% - 200px) / 2);*/
  border-right: 1px solid var(--bs-gray-400);
  position: relative;
  display: flex;
  flex-direction: column;
}

.my-raffle-order-item .item > .col-3 {
  background: var(--bs-white);
  padding: 1rem;
  border-right: 1px solid var(--bs-gray-400);
}

@media (max-width: 968px) {

  .my-raffle-order-item .item > .col-4:not(:last-child):after,
  .my-raffle-order-item .item > .col-3:not(:last-child):after {
    content: "";
    position: absolute;
    width: 20px;
    background: var(--bs-body-bg);
    height: 10px;
    border-radius: 10px 10px 0px 0px;
    right: -10px;
    bottom: 0px;
    z-index: 1;
  }
  .my-raffle-order-item .item > .col-4:not(:last-child):before,
  .my-raffle-order-item .item > .col-3:not(:last-child):before {
    content: "";
    position: absolute;
    width: 20px;
    background: var(--bs-body-bg);
    height: 10px;
    border-radius: 0px 0px 10px 10px;
    right: -10px;
    top: 0px;
    z-index: 1;
  }

}

.my-raffle-order-item .item .badge-image{
  width: 4rem !important;
  height: 2rem !important;
}

.my-raffle-order-item .item > .col-4:last-child,
.my-raffle-order-item .item > .col-3:last-child {
  border-right: 0;
}

.my-raffle-order-item .item img {
  width: 100% !important;
  height: auto !important;
  border: 0;
  object-fit: contain;
  padding: 0;
  border-radius: 0.5rem;
}

.my-raffle-order-item .item h3 {
  font-size: 14px;
  font-weight: 700;
  margin-top: 0.25rem;
}
.my-raffle-order-item .status.success {
  border: 2px solid var(--bs-success);
  background: var(--bs-success-bg-subtle);
  color: var(--bs-success);
  align-self: center;
}
.my-raffle-order-item .status.danger {
  border: 2px solid var(--bs-danger);
  background: var(--bs-danger-bg-subtle);
  color: var(--bs-danger);
}
.my-raffle-order-item .status.warning {
  border: 2px solid var(--bs-warning);
  background: var(--bs-warning-bg-subtle);
  color: var(--bs-warning);
}
.my-raffle-order-item .status.info {
  border: 2px solid var(--bs-info);
  background: var(--bs-info-bg-subtle);
  color: var(--bs-info);
}

.my-raffle-order-item .status {
  font-weight: bold;
  border-radius: var(--bs-border-radius-lg);
  float: right;
  font-size: 15px;
  text-align: center;
  padding: 10px 15px;
}

.group-header {
  font-size: 15px;
  font-weight: 700;
  padding: 5px;
  border-radius: 5px;
  margin: 0.25rem;
}


.column-center{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
