.gift-ticket-content {
  background-color: var(--bs-white);
  padding: 0.9rem;
  border-radius: 0.9rem;
}

.gift-ticket-info {
  /*!*background-color: #02d54a;*!*/
  /*!*color: white;*!*/
  /*border-radius: 0.9rem;*/
  /*margin-left: -1rem;*/
  /*!*padding: 0.8rem;*!*/
  /*height: 49%;*/
  /*!* width: 550px; *!*/
  /*display: flex;*/
  /*flex-direction: column;*/
  /*align-content: center;*/
  /*justify-content: center;*/
}

.gift-ticket-button {
  display: flex;
  margin-bottom: 0.8rem;
}

.gift-ticket-title {
  color: var(--bs-primary);
  font-weight: 700;
  text-align: center;
  font-size: 18px;
  /* padding:0.9rem 0 0.6rem 0; */
}

.gift-ticket-input {
  border-radius: 15px;
  padding: 0.1rem;
  width: 18rem;
}

.gift-ticket-arrow-icon {
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.5);
  width: 2rem;
  height: 2rem;
  border: 1px white;
}

.gift-ticket-info p:nth-last-child(1) {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.65rem;
  letter-spacing: 0.1rem;
  font-weight: 300;
}

.gift-ticket-foot {
  background-color: var(--bs-blue);
  border-radius: 0.9rem;
  margin-left: -1rem;
  padding: 0.8rem;
  height: 49%;
  /* width: 550px; */
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  margin-top: 25px;
}

.gift-ticket-foot img {
  width: 120px;
  margin: auto;
}
