.campaign-item {
    padding: 10px;
}

.campaign-widget {
    position: relative;
    line-height: 19px;
    background: var(--bs-white);
    overflow: hidden;
    padding: 0.5rem;
    border-radius: 10px;
    border: 1px solid #D0D3F5;
}

.campaign-item.active > .campaign-widget{
    background: #D0D3F5;
}

.campaign-widget img {
    width: 100%;
    max-height: 14rem;
}

.campaign-widget .body {
    padding: 1rem 0.5rem;
}

.campaign-widget .title {
    color: var(--bs-gray-800);
    font-weight: 700;
    font-size: 19px;
    display: block;
}

.campaign-widget .sub-title {
    color: var(--bs-gray-500);
    font-weight: 400;
    font-size: 15px;

}

.ribbon {
    width: 120px;
    height: 120px;
    overflow: hidden;
    position: absolute;
}

.ribbon::before,
.ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;

}

.ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 10px 0;

    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    text-transform: uppercase;
    text-align: center;
}

.ribbon.ribbon-old span {
    background-color: var(--bs-danger);
    top: 13px !important;
    color: #FFFFFF;
}

/*.ribbon.ribbon-old::after{*/
/*    border: 5px solid var(--bs-danger);*/
/*}*/


/* top right*/
.ribbon {
    top: 0;
    right: 0;
}

.ribbon::before,
.ribbon::after {
    border-top-color: transparent;
    border-right-color: transparent;
}

.ribbon::before {
    top: 0;
    left: 0;
}

.ribbon::after {
    bottom: 0;
    right: 0;
}

.ribbon span {
    left: -25px;
    top: 17px;
    transform: rotate(45deg);
}

.campaign-detail-modal {
    width: 600px !important;
}

@media screen and (max-width: 768px) {
    .campaign-detail-modal {
        max-width: 100% !important;

    }

    .default {
        width: 95% !important;
    }
}

.picture{
    border-radius: 10px;
}
.campaign-saved{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0.5rem;
    border-radius: 10px;
    border: 1px solid #D0D3F5;
}
.campaign-saved > img{
    width: 30px;
}
