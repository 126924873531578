.game-select-dropdown {
  position: relative;
  display: inline-block;
}

.game-select-dropdown .btn {
  color: var(--bs-white);
  outline: none;
  box-shadow: none;
  background-color: inherit;
  border: none;
  transition: none;
  font-size: 12px;
}


.game-select-dropdown .btn:focus,
.game-select-dropdown .btn:active {
  outline: none;
  box-shadow: none;
  background-color: inherit;
  border: none;
  transition: none;
}

.game-select-dropdown-menu {
  display: none;
  position: absolute;
  z-index: 1000;
  background-color: #ffffff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.9);
  padding: 5px 0;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
}

.game-select-dropdown:hover .game-select-dropdown-menu {
  display: block;
}

.game-select-dropdown-menu .game-img {
  width: 80px;
  height: 80px;
  border-radius: 5px;
}

.games-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  padding: 10px;
}

.dropdown-item .badge {
  position: absolute;
  top: 1px;
  right: 1px;
}

.dropdown-item {
  position: relative;
  font-size: 14px;
  color: #212529;
  text-decoration: none;
  background-color: #ffffff;
  text-align: center;
  border-radius: 4px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  /* background-color: rgba(0, 0, 0, 0.9);   */
}

body.menu-open {
  pointer-events: none;
}

body.menu-open .game-select-dropdown,
body.menu-open .game-select-dropdown * {
  pointer-events: auto;
}
