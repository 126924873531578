
.game-widget > img {
  border-radius: 10px;
  max-width: 100%;
}

.game-widget {
  padding: 6px;
  border-radius: var(--bs-border-radius-xl);
  line-height: 19px;
  background: var(--bs-white-50);
  overflow: hidden;
  border: 1px solid var(--bs-white);
  /* background-color: #e5eae9 !important; */
  background-color: white !important;
  margin-block: 10px;
  filter: drop-shadow(0 0 6px #cfe6e7);
  transition: 300ms;
}

.game-widget .body {
  padding: 0.5rem;
  /* background: var(--bs-yellow); */
  background: var(--bs-primary);
  border-radius: 10px;
  color: var(--bs-white);
}

.game-widget .body.playable {
  /* background: var(--bs-blue-light); */
  background: var(--bs-warning);
}

.game-widget .body.remanded {
  background: var(--bs-primary);
  cursor: not-allowed;
  /*padding: 0.75rem 0px;*/
  font-size: 10px;
}

.game-widget .sub-title {
  /*color: var(--bs-gray-500);*/
  font-weight: 400;
  font-size: 15px;
  display: none;
}

.game-widget .title {
  /*color: var(--bs-gray-800);*/
  font-weight: 600;
  font-size: 14px;
  display: block;
  text-align: center;
  margin-top: 0.5rem;
}

.game-widget .label a {
  /*color: var(--bs-gray-800);*/
}

.game-widget .label {
  /*color: var(--bs-gray-800);*/
  font-weight: bold;
  font-size: 13px;
  display: block;
  margin-top: 0.1rem;
  text-align: center;
}

.game-widget:hover {
  filter: drop-shadow(0 0 6px #6f76e6) !important;
  transition: 300ms;
}

/*.game-widget.active > img{*/
/*    object-fit: cover;*/
/*    height: 138px;*/
/*    width: 175px;*/
/*    object-position: 0px 0px;*/
/*    margin-bottom:40px;*/
/*}*/
/*.game-widget.active{*/
/*    background-color: #4a8ef7;*/
/*    background-image: linear-gradient(45deg, #4a8ef7 7%, #8ed5f1 100%);*/
/*}*/
/*.game-widget.active .sub-title{*/
/*    color: var(--bs-primary-bg-subtle);*/
/*    font-weight: 400;*/
/*    font-size: 15px;*/
/*    display: block;*/
/*}*/
/*.game-widget.active .title{*/
/*    color: var(--bs-white);*/
/*    font-weight: 700;*/
/*    font-size: 21px;*/
/*    display: block;*/
/*}*/
/*.game-widget.active .label a{*/
/*    color: var(--bs-white);*/
/*}*/
/*.game-widget.active .label{*/
/*    color: var(--bs-white);*/
/*    font-weight: 400;*/
/*    font-size: 15px;*/
/*    display: block;*/
/*    margin-top: 1rem;*/
/*}*/

.game-widget > img.games {
  height: 145px;
  width: 100% !important;
  max-width: 100%;
  margin: 0;
  object-fit: cover;
  display: block;
  transition: 300ms;
}

.game-gif {
  height: 145px;
  width: 100% !important;
  max-width: 100%;
  margin: 0;
  object-fit: contain;
  display: block;
  top: -145px;
  opacity: 0;
  transition: 300ms;
  position: relative;
  margin-bottom: -145px;
}

#mainWrapper > div:nth-child(1) > div > div {
  white-space: nowrap;
}

.game-info {
  margin-bottom: 3rem;
}

@media (max-width: 968px) {
  .game-info {
    margin-top: 96vh !important;
  }

  .game-info .white-space-normal {
    font-size: 12px !important;
  }
}

.game-user-balance {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.game-user-balance .lines {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
.game-user-balance .lines span {
  line-height: 15px;
}
.game-user-balance .lines {
  font-weight: 600;
  width: 100%;
  min-width: 60px;
  margin-top: 2px;
  color: #ffff;
  font-size: 12px;
}
.game-user-balance label {
  font-size: 12px;
}
