#tombala-iframe {
  height: calc(100vh - 55px);
  width: 100%;
  border: none;
  margin: 0;
  padding: 0;
}


#gameIFrame {
  width: 100% !important;
  height: 100% !important;
  border: none;
  text-align: center;
  background-color: Black;
}

@media (max-width: 968px) {
  #tombala-iframe {
    height: calc(100vh - 55px);
    width: 100%;
    position: fixed;
    top: 55px;
    left: 0;
    bottom: 0;
    right: 0;
    border: none;
    margin: 0;
    padding: 0;
    overflow: visible;
    z-index: 1;
  }
}
