.product-widget {
  background: var(--bs-white);
  filter: drop-shadow(0 0 3px #cfe6e7);
  border-radius: var(--bs-border-radius-lg);
  overflow: hidden;
}

.product-widget .head {
  text-align: center;
  height: 65px;
}

.product-widget .head h2 {
  font-size: 14px;
  font-weight: 700;
}
.product-widget .body {
  height: 145px;
  padding: 0px;
}
.product-widget .body img {
  width: 100%;
  object-fit: contain;
}

.product-widget .foot {
  position: relative;
  height: 90px;
  margin-top: 100px;
  z-index: 1;
}
.product-widget .foot button {
  background: var(--bs-primary);
  color: var(--bs-white);
  border: none;
  /* border-bottom-right-radius: var(--bs-border-radius-lg);
  border-bottom-left-radius: var(--bs-border-radius-lg); */
  width: 100%;
  padding: 1rem;
  text-align: left;
  bottom: 0;
  position: absolute;
  font-size: 12px;
  font-weight: 600;
}
.product-widget .foot .add {
  background: var(--bs-primary);
  border-top-right-radius: var(--bs-border-radius-lg);
  border-top-left-radius: var(--bs-border-radius-lg);
  z-index: 1;
  position: relative;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  height: 45px;
  padding: 0.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product-widget .foot .add .input i {
  border: 2px solid #fff;
  border-radius: 50%;
  width: 24px;
  min-width: 24px;
  height: 24px;
  font-weight: 500;
  text-align: center;
  line-height: 18px;
  color: white;
  font-size: 18px;
  font-style: normal;
  cursor: pointer;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.product-widget .foot .add .input input:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}
.product-widget .foot .add .input span {
  color: var(--bs-primary);
  font-weight: 700;
  display: block;
  font-size: 16px;
  line-height: 20px;
  margin-top: 4px;
  margin-right: 10px;
  width: 80%;
  text-align: center;
}
.product-widget .foot .add .input input {
  width: calc(100% - 56px);
  background: transparent;
  color: #fff;
  font-weight: 700;
  border: 0;
  text-align: center;
  font-size: 17px;
}
.product-widget .foot .add .input {
  display: flex;
  background: var(--bs-primary);
  /* border-radius: var(--bs-border-radius-pill); */
  border: 0;
  padding: 0.3rem;
  min-width: 100px;
  height: 35px;
  align-items: center;
  justify-content: center;
}
.product-widget .foot .add div.price {
  float: right;
  font-weight: 700;
  color: var(--bs-white);
  text-align: right;
  line-height: 38px;
}
.product-widget .foot button span.price {
  float: right;
  font-weight: bold;
}
.product-widget .foot button.added {
  background: var(--bs-warning);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -0.5rem;
  padding-top: 1rem;
  z-index: 0;
  text-align: center;
  font-weight: 700;
}
.product-widget .foot button.added span.price {
  display: none;
}

.product-detail-modal {
  width: 800px !important;
  overflow: hidden;
}
.product-detail-modal .info {
  white-space: pre-wrap;
}
.product-detail-modal .picture {
  width: 100%;
}
.product-detail-modal > .modal-footer {
}

.prize-request {
  background: var(--ps-body-bg) !important;
}
.prize-request .header {
  background: var(--bs-white);
  border-bottom: 0px solid var(--bs-success);
  padding-bottom: 1rem !important;
  text-align: center;

  --mask: radial-gradient(5.59px at 50% calc(100% - 7.5px), #000 99%, #0000 101%) calc(50% - 10px) 0/20px 100%,
    radial-gradient(5.59px at 50% calc(100% + 2.5px), #0000 99%, #000 101%) 50% calc(100% - 5px) / 20px 100% repeat-x;
  -webkit-mask: var(--mask);
  mask: var(--mask);
}
.prize-request .item {
  border-radius: 0.5rem;
  overflow: hidden;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  background-color: #fff;
  /* border:0.5px solid; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-size: 14px;
}
.prize-request .item .border-dashed {
  border-right: 1px dashed var(--bs-gray-400);
}

.prize-request .item img {
  width: 100px !important;
  height: 100px !important;
  border: 0;
  object-fit: contain;
}
.prize-request .item h3 {
  font-size: 16px;
  font-weight: 700;
}
.prize-request .status.success {
  border: 2px solid var(--bs-success);
  background: var(--bs-success-bg-subtle);
  color: var(--bs-success);
}
.prize-request .status.danger {
  border: 2px solid var(--bs-danger);
  background: var(--bs-danger-bg-subtle);
  color: var(--bs-danger);
}
.prize-request .status.warning {
  border: 2px solid var(--bs-warning);
  background: var(--bs-warning-bg-subtle);
  color: var(--bs-warning);
}
.prize-request .status.info {
  border: 2px solid var(--bs-info);
  background: var(--bs-info-bg-subtle);
  color: var(--bs-info);
}
.prize-request .status {
  font-weight: bold;
  border-radius: var(--bs-border-radius-lg);
  float: right;
  /* width: 90px;
  line-height: 90px; */
  font-size: 14px;
  text-align: center;
}
.prize-request .youtube img {
  height: 50px !important;
}

@media (max-width: 768px) {
  .product-widget {
    height: 340px;
  }
  .product-widget .head {
    padding-top: 10px;
  }
  .product-widget .foot {
    position: relative;
    height: 90px;
    margin-top: 45px;
    z-index: 1;
  }

  .prize-request .item {
    border-radius: 0.5rem;
    overflow: hidden;
    padding: 0.5rem 0.4rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    background-color: #fff;
    /* border:0.5px solid; */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-size: 12px;
  }

  .prize-request .item img {
    width: 100% !important;
    height: 100% !important;
    border: 0;
    object-fit: fill;
  }

  .prize-request .item h3 {
    font-size: 14px;
    font-weight: 700;
  }

  .prize-request .status {
    display: inline-block;
    padding: 0.3rem;
    border-radius: 0.5rem;
    color: #fff;
    font-weight: bold;
    text-align: center;
    font-size: 9px;
  }
}
