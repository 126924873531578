.support-hints .content {
    border: 1px dashed var(--bs-gray-400);
    padding: 0.5rem;
    border-radius: var(--bs-border-radius-lg);
}

.support-ticket-form textarea {
    width: 100%;
    height: 100px;
    border: var(--bs-border-width) solid var(--bs-border-color);
    resize: none;
    border-radius: var(--bs-border-radius-lg);
    padding: 0.5rem;
}

.support-ticket-list .date {
    color: var(--bs-gray-600);
    font-size: 0.8rem;
}

.support-ticket-list .ticket-responses h3 {
    font-size: 16px;
    font-weight: 700;
}

.support-ticket-list .ticket-responses .attach {
    text-decoration: none;
    color: var(--bs-dark);
    padding-top: 2px;
}

.support-ticket-list .ticket-responses .ticket-response {
}

.support-ticket-list .message {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 17px;
}

.support-faq .faq-list {
    list-style: none;
    cursor: pointer;
    padding: 0;

}

.support-faq .faq-list li {
    border-radius: var(--bs-border-radius);
    overflow: hidden;
    margin-bottom: 5px;
}

.support-faq .faq-list li:nth-child(odd) a {
    background: #646BDD20;
}

.support-faq .faq-list li a:hover {
    background: #646BDD40;
}

.support-faq .faq-list > li > p {
    border: 1px solid #646BDD40;
    border-top: 0;
}

.support-faq .faq-list li a {
    padding: 1rem;
    display: block;
    text-decoration: none;
    font-weight: 600;
}

.support-faq .faq-list li:not(.active) ul {
    display: none;
}

.support-faq .faq-list a.active {
    background: #646BDD20;
    font-weight: bold;
}

.support-faq .faq-list > li > ul > li {
    border-top: 1px dashed #646BDD40;
    padding: 1rem;
    padding-left: 2rem;
    display: block;
    font-weight: bold;
}

.support-faq .faq-list li ul {
    list-style: decimal;
    padding: 0;
}


@media (max-width: 968px) {
    .support-wrapper {
        margin-top: 1rem;
        padding: 1rem !important;
    }

    .container > .support-wrapper {
        margin-left: calc(calc(var(--bs-gutter-x) * .5) * -1) !important;
        margin-right: calc(calc(var(--bs-gutter-x) * .5) * -1) !important;
    }
}



.accordion-list{
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}
