.ticket-widget .footer .info > span{ display: block; }
.ticket-widget .footer .info{
    background: var(--bs-white);
    border-top-left-radius: var(--bs-border-radius);
    border-bottom-left-radius: var(--bs-border-radius);
    width: 70%;
    padding: 0.5rem;
    color: #1E3D98;
}
.ticket-widget .footer .status{
    width: 30%;
    background: var(--bs-warning);
    padding: 0.5rem;

    justify-content: center;
    align-items: center;
    display: flex;
    font-weight: 600;

    border-top-right-radius: var(--bs-border-radius);
    border-bottom-right-radius: var(--bs-border-radius);
}
.ticket-widget .footer .status.playable{ }
.ticket-widget .footer{

    padding: 0 0.75rem 1.5rem 0.75rem;

    display: flex;

    background: #D7DAF4;
    --mask: radial-gradient(5.59px at 50% calc(100% - 7.5px), #000 99%, #0000 101%) calc(50% - 10px) 0 / 20px 100%, radial-gradient(5.59px at 50% calc(100% + 2.5px), #0000 99%, #000 101%) 50% calc(100% - 5px) / 20px 100% repeat-x;
    -webkit-mask: var(--mask);
    mask: var(--mask);

}
.ticket-widget .header .avatar{
    border-radius: 50%;
    width: 48px;
    min-width: 48px;
    height: 48px;
    min-height: 48px;
    object-fit: cover;
    float: left;
    margin-right: 10px;
    border:2px solid #FFFFFF;
    object-position: top;
}

.ticket-widget .header{
    border-bottom: 2px dashed #1E3D9850;

    padding: 0.75rem;
    padding-top: 1rem;

    background: #D7DAF4;

    --mask: radial-gradient(5.59px at 50% 7.5px, #000 99%, #0000 101%) calc(50% - 10px) 0 / 20px 100%, radial-gradient(5.59px at 50% -2.5px, #0000 99%, #000 101%) 50% 5px / 20px 100% repeat-x;
    -webkit-mask: var(--mask);
    mask: var(--mask);
}
.ticket-widget .header > span{
    display: block;
}
.ticket-widget .header h3{
    font-size: 14px;
    font-weight: bolder;
    color: #1E3D98;
    margin-bottom: 0;
    margin-top: 5px;
    float: left;
    width: calc(100% - 64px);
    text-wrap: nowrap;
}

.ticket-widget .body .run > div:first-child{
    font-size: var(--font-size-sm);
    font-weight: 600;
    color: #1E3D98;
    text-transform: uppercase;
    margin-top: 10px;
    margin-bottom: 5px;
    padding-bottom: 5px;
    line-height: var(--font-size-sm);
    border-bottom: 1px solid #1E3D98;
}
.ticket-widget .body .run{
    text-align: center;
    border-radius: var(--bs-border-radius-lg);
    margin-right: 2px;
    margin-left: 2px;
    min-height: 220px;
    width: 100%;
}
.ticket-widget .body .run div{
    line-height: 1.15;
}
.ticket-widget .body .run:nth-child(odd){
    background: #FFFFFF90;
}
.ticket-widget .body .run:nth-child(even){
    background: #FFFFFF40;
}
.ticket-widget .body{
    display: flex;
    flex-direction: column;
    padding: 0.75rem;
    background: #D7DAF4;
}

.ticket-widget .body .legs{
    display: flex;
}

.ticket-widget .mobile-body{
display: block;
}

.ticket-widget .mobile-body .col-4{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ticket-widget .mobile-body .col-4 .info{
    background: #ffffff;
    padding: 10px;
    text-align: center;
    height: 100%;
    border-top-left-radius: var(--bs-border-radius);
    border-top-right-radius:var(--bs-border-radius);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ticket-widget .mobile-body .col-4 .status{
    width: 100%;
    background: var(--bs-warning);
    padding:  1.25rem 0.5rem;

    justify-content: center;
    align-items: center;
    display: flex;
    font-weight: 600;
    color: #FFFFFF;
    font-size: 1rem;

    border-bottom-right-radius: var(--bs-border-radius);
    border-bottom-left-radius: var(--bs-border-radius);
}
