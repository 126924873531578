@import url("https://fonts.googleapis.com/css2?family=Saira:ital,wght@0,100..900;1,100..900&display=swap");

:root,
[data-bs-theme="light"] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #646bdd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans",
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #646bdd;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #1e3d98;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;

  --font-size: 1rem;
  --font-size-sm: 0.75rem;
  --font-size-md: 1.25rem;
  --font-size-lg: 1.75rem;
}

[data-bs-theme="dark"] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

body {
  margin: 0;
  font-family: "Saira";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--bs-body-bg);
  font-size: 0.85rem;
  color: #1e3d98;
  overflow-x: hidden;
}

#root,
.App {
  position: relative;
}



*:not(html) {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

*:not(html)::-webkit-scrollbar {
  display: none;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.hide-stepper::-webkit-outer-spin-button,
.hide-stepper::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hide-stepper[type="number"] {
  -moz-appearance: textfield;
}

.page-widget h1 {
  font-size: var(--font-size-lg);
  font-weight: 600;
  color: #1e3d98;
  padding-left: 1rem;
}

.page-widget.dark h1 {
  color: #ffffff;
}

.page-widget h2 {
  font-size: var(--font-size);
  font-weight: 600;
  color: #1e3d98;
  padding: 1rem;
}

.page-widget .body {
  padding: 0 1rem 0 1rem;
}

.page-widget {
  background: #ffffff;
  border-radius: var(--bs-border-radius-xl);
  /*box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);*/
  overflow: hidden;
}

.page-widget.dark {
  background: #4249b3;
}

.page-widget .page-widget {
  /*border-radius: var(--bs-border-radius-lg);*/
}

.page-widget.side-menu {
  overflow: hidden;
}

.page-widget.side-menu h3 {
  background: var(--bs-warning);
  margin-bottom: 10px;
  padding: 10px;
  font-size: var(--font-size-md);
}

.page-widget.side-menu ul {
  list-style: none;
  margin: 0;
  padding: 0 0 10px 0;
  border-radius: var(--bs-border-radius-lg);
  overflow: hidden;
}

.page-widget.side-menu li a {
  align-items: center;
  border-radius: var(--bs-border-radius);
  color: #1e3d98;
  /*display: block;*/
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  padding: 15px 10px;
  text-decoration: none;
}

.page-widget.side-menu li.active a {
  background: #d0d3f5;
}

.page-widget.side-menu li a img {
  margin-right: 15px;
  filter: invert(8%) sepia(90%) saturate(4287%) hue-rotate(260deg) brightness(115%) contrast(120%);
}

.bg-dashed {
  position: relative;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-size: 0 100%, 100% 0, 0 100%, 100% 1.5px;
  background-repeat: no-repeat;

  background-image: repeating-linear-gradient(0deg, #4249b3, #4249b3 5px, transparent 5px, transparent 10px),
    repeating-linear-gradient(90deg, #4249b3, #4249b3 5px, transparent 5px, transparent 10px),
    repeating-linear-gradient(180deg, #4249b3, #4249b3 5px, transparent 5px, transparent 10px),
    repeating-linear-gradient(270deg, #4249b3, #4249b3 5px, transparent 5px, transparent 10px);
  -o-border-image: repeating-linear-gradient(0deg, #4249b3, #4249b3 5px, transparent 5px, transparent 10px);
  border-image: repeating-linear-gradient(0deg, #4249b3, #4249b3 5px, transparent 5px, transparent 10px);
}

.bg-dashed:after {
  content: "";
  position: absolute;
  width: 10px;
  background: #4249b3;
  height: 20px;
  border-radius: 10px 0 0 10px;
  right: 0;
  bottom: -10px;
}

.bg-dashed:before {
  content: "";
  position: absolute;
  width: 10px;
  background: #4249b3;
  height: 20px;
  border-radius: 0 10px 10px 0;
  left: 0;
  bottom: -10px;
}

.bg-vertical-line {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) calc(50% - 0px),
    rgba(66, 73, 179, 1) calc(50% - 0px),
    rgba(0, 0, 0, 0) calc(50% + 1px)
  );
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 1rem;
  --bs-nav-link-font-weight: 600;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);

  background: rgb(0, 212, 255);
  background: linear-gradient(180deg, #1e3d98 0%, #3f42b6 100%);

  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  border-radius: var(--bs-border-radius-xl) var(--bs-border-radius-xl) 0 0;
}

.nav-item > * {
  float: left;
}

.nav-item > svg.left {
  -webkit-transform: scale3d(-1, 1, 1);
  transform: scale3d(-1, 1, 1);
}

.nav-item > svg {
  width: 45px;
  height: 52px;
  fill: #fff;

  margin-left: -0.5px;
  margin-right: -0.5px;
  margin-bottom: -0.5px;
}

.nav-item {
  display: inline-block;
}

.nav-item:not(.active) > svg {
  display: none;
}

.nav-link {
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: #ffffff;
  text-decoration: none;
  background: none;
  border: 0;
  cursor: pointer;
  text-wrap: nowrap;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.nav-link.disabled,
.nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius-xl);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-nav-link-color);
  --bs-nav-tabs-link-active-bg: #ffffff;
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
}

.nav-tabs .nav-item:first-child.active .nav-link {
  padding-left: 30px;
  border-top-left-radius: var(--bs-border-radius-xl);
}

.nav-tabs .nav-item:first-child.active .left {
  display: none !important;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

.nav-tabs .nav-item.active .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
  padding: var(--bs-nav-link-padding-y) 0;
}

.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.info-circle {
  border-radius: 50%;
  border: 1.3px solid #1e3d98;
  height: 18px;
  width: 18px;
  font-size: 11px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  font-weight: 700;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.btn-check:checked + .btn:focus-visible,
:not(.btn-check) + .btn:active:focus-visible,
.btn:first-child:active:focus-visible,
.btn.active:focus-visible,
.btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn-check:checked:focus-visible + .btn {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-icon img {
  width: 20px;
  margin-right: 10px;
}

.btn-icon {
  text-align: left;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #646bdd;
  --bs-btn-border-color: #646bdd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.btn-secondary {
  --bs-btn-color: #646bdd;
  --bs-btn-bg: #bec8f7;
  --bs-btn-border-color: #bec8f7;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-six {
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: var(--bs-link-color);
  --bs-btn-border-color: var(--bs-link-color);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--bs-link-color);
  --bs-btn-hover-border-color: var(--bs-link-color);
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--bs-link-color);
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--bs-link-color);
  --bs-btn-disabled-border-color: var(--bs-link-color);
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #1e3d98;
  --bs-btn-bg: #d1d3f2;
  --bs-btn-border-color: #d1d3f2;
  --bs-btn-hover-color: #1e3d98;
  --bs-btn-hover-bg: #bfc1e8;
  --bs-btn-hover-border-color: #bfc1e8;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #1e3d98;
  --bs-btn-active-bg: #bfc1e8;
  --bs-btn-active-border-color: #d1d3f2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1e3d98;
  --bs-btn-disabled-bg: #d1d3d2;
  --bs-btn-disabled-border-color: #d1d3d2;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-login {
  --bs-btn-color: #1e3d98;
  --bs-btn-bg: #ffca56;
  --bs-btn-border-color: #ffca56;
  --bs-btn-hover-color: #1e3d98;
  --bs-btn-hover-bg: #ffca56;
  --bs-btn-hover-border-color: #ffca56;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #1e3d98;
  --bs-btn-active-bg: #ffca56;
  --bs-btn-active-border-color: #ffca56;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1e3d98;
  --bs-btn-disabled-bg: #ffca56;
  --bs-btn-disabled-border-color: #ffca56;
  font-weight: bold;
}

.btn-create-account {
  --bs-btn-color: #1e3d98;
  --bs-btn-border-color: #1e3d98;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1e3d98;
  --bs-btn-hover-border-color: #1e3d98;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #1e3d98;
  --bs-btn-active-bg: #1e3d98;
  --bs-btn-active-border-color: #1e3d98;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fff;
  --bs-gradient: none;
  font-weight: bold;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #646bdd;
  --bs-btn-border-color: #646bdd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #646bdd;
  --bs-btn-hover-border-color: #646bdd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #646bdd;
  --bs-btn-active-border-color: #646bdd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #646bdd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #646bdd;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: underline;
}

.btn-link:focus-visible {
  color: var(--bs-btn-color);
}

.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg,
.btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm,
.btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: rgb(99, 107, 221, 0.2);
  --bs-table-border-color: #a6b5cc;
  --bs-table-striped-bg: var(--bs-light);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #b5b6b7;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #a7b9b1;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #a6c3ca;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #ccc2a4;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #c6acae;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #c6c7c8;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #4d5154;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

form .form-row {
  display: flex;
  margin-left: -0.35rem;
  margin-right: -0.35rem;
  margin-bottom: 1rem;
}

form .form-column {
  display: flex;
  flex-direction: column;
  margin-left: -0.35rem;
  margin-right: -0.35rem;
  margin-bottom: 1rem;
}

form .form-row > div {
  padding-left: 0.35rem;
  padding-right: 0.35rem;
}

form .sideInput {
  position: relative;
  background: var(--bs-white);
  border: 1px solid #1e3d98;
  border-radius: var(--bs-border-radius);
  width: 100%;
}

form .sideInput > img {
  position: absolute;
  right: -5px;
  top: 50%;
  transform: translate(-50%, -50%);
}

form input {
  background: var(--bs-white);
  border: 1px solid #1e3d98;
  padding: 0.5rem;
  border-radius: var(--bs-border-radius);
  width: 100%;
}

form input[type="checkbox"] {
  margin-right: 10px;
  margin-top: 0;
  width: 17px;
  height: 17px;
  border: 2px solid #1e3d98;
  accent-color: #1e3d98;
}

form .checkbox label {
  float: left;
  display: contents;
}

form .invalid-feedback {
  font-size: 13px;
  color: var(--bs-danger);
  margin-bottom: 5px;
}

.col-data {
  width: calc(100% - 270px);
}

.betting-run-tabs {
  width: 100%;
  overflow: scroll;
}

.betting-run-tabs .nav.nav-tabs {
  min-width: 100%;
  display: flex;
  width: max-content;
}

.col-betslip {
  width: 270px;
}

.betting-table > thead > tr > th:first-child {
  border-radius: 10px 0 0 10px;
}

.betting-table > thead > tr > th:last-child {
  border-radius: 0 10px 10px 0;
}

.betting-table > thead > tr > th {
  background: #646bdd30;
  padding-top: 15px;
  padding-bottom: 15px;
}

.betting-table > thead > tr > th,
.betting-table > tbody > tr > td {
  white-space: nowrap;
  line-height: 16px;
  font-size: 13px;
}

.betting-table > tbody > tr:nth-child(4n-2) > td {
  background: #646bdd15;
}
.betting-table > tbody > tr.ticket:nth-child(4n-2) > td {
  background: #646bdd30;
}

.betting-table .seperator {
  padding: 4px;
}

.betting-table .origin-father {
  color: #0a53be;
}

.betting-table .origin-mother {
  color: var(--bs-danger);
}

.betting-table .jockey-uniform {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  margin-right: 5px;
  padding: 5px;
  border: 1px solid var(--bs-gray-500);
}

.betting-table > tbody > tr:not(.ticket-detail):hover > td {
  background: #646bdd30;
}

.betting-table > tbody > tr.active:not(.ticket-detail) > td .text-dark {
  color: #ffffff !important;
}
.betting-table > tbody > tr.active:not(.ticket-detail) > td.text-success {
  color: #ffffff !important;
}
.betting-table > tbody > tr.active:not(.ticket-detail) > td {
  background: #646bdd;
  font-weight: 600;
  color: var(--bs-white);
}

.ticket-detail > th > .row {
  margin-left: -8px;
  margin-right: -8px;
}

.ticket-detail > th > .row .two-btn {
  width: 50%;
  margin-right: 0;
  margin-left: 0;
}

.ticket-detail > th > .row .two-btn:first-child {
  width: 50%;
  margin-right: 0;
  margin-left: 0;
  border-radius: 0 0 0 10px;
}
.ticket-detail > th > .row .two-btn:nth-child(2) {
  width: 50%;
  margin-right: 0;
  margin-left: 0;
  border-radius: 0 0 10px 0;
}

.ticket-detail-container {
  border-left: 10px solid #646bdd;
  border-right: 10px solid #646bdd;
  border-bottom: 10px solid #646bdd;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  width: calc(100% + 1rem);

  background: #646bdd;
}
.ticket-detail-inner-content {
  border-radius: 10px;
  background: #ffffff;

  padding: 0.5rem;
}

.betting-table > tbody > tr > td:first-child {
  border-radius: 10px 0 0 10px;
}
.betting-table > tbody > tr.active > td:first-child {
  border-radius: 10px 0 0 0;
}

.betting-table > tbody > tr > td:last-child {
  border-radius: 0 10px 10px 0;
}

.betting-table > tbody > tr.active > td:last-child {
  border-radius: 0 10px 0 0;
}

.betting-table tbody tr.horse-added .add-to-betslip {
  background: #646bdd;
  color: var(--bs-white);
}

.betting-table tbody tr.horse-added td {
  background: #646bdd60;
}

.select-all-horse.selected {
  background: var(--bs-danger) !important;
  color: #fff;
  border-color: var(--bs-danger);
}

.mobile-betting-table {
  display: flex;
  margin-bottom: 5px;
}

.mobile-betting-table.horse-added > div.horse-no {
  background: #2ed453;
}

.mobile-betting-table > div.horse-no {
  width: 25px;
  background: #1e3d98;
  border-radius: var(--bs-border-radius-lg) 0 0 var(--bs-border-radius-lg);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--bs-white);
  font-weight: 600;
}

.mobile-betting-table > div.horse-detail {
  width: calc(100% - 185px);
  background: #d7daf4;
  padding: 5px 10px;
}

.mobile-betting-table.horse-added > div.horse-detail {
  background: rgba(46, 212, 83, 0.2);
}

.mobile-betting-table > div.horse-other {
  width: 160px;
  /*background: #F0F1FF;*/
  background: #d7daf4;
  text-align: right;
}
.mobile-betting-table.horse-added > div.horse-other {
  background: rgba(46, 212, 83, 0.2);
}

.mobile-betting-table .mobile-agf-bar:before {
  position: absolute;
  content: "";
  /*width: attr(data-agf %, 50%);*/
  width: var(--data-agf);
  height: 100%;
  background: #c004;
  margin-top: 0;
  margin-left: -5px;
}

.mobile-betting-table .mobile-agf-bar.agf1:before {
  background: #56ff7b84 !important;
}

.mobile-betting-table .mobile-agf-bar {
  border: 1px solid #1e3d98;
  border-radius: 5px;
  font-size: 11px;
  margin-top: 5px;
  padding: 0 5px;
  position: relative;
  font-weight: bold;
  min-height: 17px;
}

.mobile-betting-table .mobile-ganyan {
  border: 1px solid #1e3d98;
  border-radius: 5px;
  font-size: 11px;
  padding: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.1rem;
  background: #c004;
  font-weight: 600;
  min-width: 60px;
}
.mobile-betting-table .mobile-ganyan.minganyan {
  background: #56ff7b84 !important;
}

.mobile-foot-betslip.sticky {
  position: fixed;
  /*top: calc(100vh - 30vh);*/
  bottom: 20px;
  z-index: 1;
  width: calc(100% - 10px) !important;
  left: 5px;
}

.mobile-foot-betslip .coupon-legs > div:first-child {
  border-radius: 10px 0 0 10px;
}

.mobile-foot-betslip .coupon-legs > div:last-child {
  border-radius: 0 10px 10px 0;
}

.mobile-foot-betslip .coupon-legs > div span.horses {
  font-weight: normal;
  font-size: 13px;
  margin-top: 5px;
}

.mobile-foot-betslip .coupon-legs > div span {
  font-weight: 600;
  display: block;
  font-size: 11px;
}

.mobile-foot-betslip .coupon-legs > div {
  text-align: center;
  line-height: 13px;
  min-height: 60px;
  background: #fff;
  border-right: 1px solid #646bdd4a;
}

.mobile-foot-betslip h2 {
  padding: 0;
}

.mobile-foot-betslip {
  background: var(--bs-warning);
  height: 120px;
  width: 100%;
  border-radius: var(--bs-border-radius-lg);
  padding: 0.5rem;
}

.ticket-detail h3 {
  font-size: 19px;

  margin-left: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: 0;
  width: calc(100% + 1rem);

  padding: 10px 10px;
  background: #646bdd;
  color: #fff;
}

.ticket-detail .ticket-header {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  width: calc(100% + 1rem);
}

.ticket-detail .coupon-legs {
  margin-left: -0.85rem;
  margin-right: -0.85rem;
}

.ticket-detail .foot {
  /*margin-top: 10px;*/
  /*margin-left: -0.5rem;*/
  /*margin-right: -0.5rem;*/
  padding: 10px 10px;
  background: #646bdd;
  color: #fff;
  border-radius: 0 0 10px 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  width: calc(100% + 1rem);
}

.ticket-detail.new .foot.danger {
  background: var(--bs-danger);
}

.ticket-detail.new .foot.warning {
  background: var(--bs-warning);
  color: #1f3d98;
}

.ticket-detail.new .foot:not(.danger):not(.warning) {
  background: #646bdd;
}

.ticket-detail.win .foot,
.ticket-detail.win h3 {
  background: var(--bs-success);
}

.ticket-detail.los .foot,
.ticket-detail.los h3 {
  background: var(--bs-danger);
}

.bettingtype-options {
  background: var(--bs-white);
  border-radius: var(--bs-border-radius-xl);
  margin-bottom: 1.25rem;
}

.betting-run-info {
  border-radius: 10px;
  background: #646bdd30;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  /*color: #ffffff;*/
  min-height: 56px;
}
.betting-run-info-mobile {
  border-radius: 5px;
  background: #646bdd30;
  color: #ffffff;
}

.betting-run-info span {
  margin-right: 5px;
  font-weight: 600;
}

.bettingtype-options select {
  width: 100%;
  height: 45px;
}

.bettingtype-options label {
  width: 100%;
  font-weight: 600;
  margin-bottom: 5px;
}

.btn-muhtemeller,
.btn-agf {
  background: #9ca0e9;
  color: var(--bs-white);
  font-weight: 600;
  border-radius: var(--bs-border-radius);
  padding: 0.5rem;
  width: 100%;
  text-align: center;
  cursor: pointer;
  height: 47px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.btn-muhtemeller:hover,
.btn-agf:hover {
  background: #646bdd;
}

.btn-muhtemeller.active,
.btn-agf.active {
  background: #646bdd;
  color: #ffffff;
}

.btn-photo,
.btn-video {
  background: #9ca0e9;
  color: var(--bs-white);
  font-weight: 600;
  border-radius: var(--bs-border-radius);
  width: 100%;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.btn-photo:hover,
.btn-video:hover {
  background: #464bb4;
}

.btn-photo.active,
.btn-video.active {
  background: #464bb4;
  color: #ffffff;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

select {
  border-radius: var(--bs-border-radius);
  border: 1px solid #1e3d98;
  padding: 0.5rem;
  background: #d0d3f560;
  -webkit-appearance: none;
}

.nowrap-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.last-run-K {
  color: #996633;
  font-weight: bold;
}

.last-run-C {
  color: var(--bs-success);
  font-weight: bold;
}

.add-to-betslip {
  background-color: #fff;
  border: 1px solid #41434750;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 32px;
  justify-content: center;
  align-items: center;
  width: 28px;
  cursor: pointer;
}

.horse-jewelry:hover div,
.tooltip:hover > .tooltip-text {
  display: block;
}

.horse-jewelry > div,
.tooltip > .tooltip-text {
  display: none;
  position: absolute;
  width: 200px;
  background: #bfc1e8;
  border-radius: var(--bs-border-radius);
  padding: 0.75rem;
  white-space: normal;
  margin-top: 5px;
  z-index: 1;
}

.horse-jewelry > div::after,
.tooltip:hover > .tooltip-text::after {
  content: "";
  position: absolute;
  top: -14px;
  left: 15%;
  margin-left: -5px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent #bfc1e8 transparent;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

.backdrop-blur {
  display: none;
  background: #00000060;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  z-index: 2;
}

.modal {
  position: fixed;
  z-index: 1050;
  display: none;
  max-width: 460px;
  max-height: calc(100vh - 40px);

  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  overflow: hidden;
  outline: 0;
  border-radius: var(--bs-border-radius-xl);
}

.modal .footer {
  background: var(--bs-white);
}

.modal .body {
  padding: 10px;
  overflow: scroll;
  background: var(--bs-white);
}

.modal .header {
  padding: 10px;
  border-bottom: 1px solid #00000033;
  background: #ffffff;
  font-weight: 700;
  position: relative;
}

.modal .header img {
  display: block;
  width: 75px;
  background: #ffffff;
  border-radius: 50%;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
}

.modal.success .header {
  background: unset;
  color: var(--bs-primary);
  border-bottom: 0 solid var(--bs-primary);
  text-align: center;
  padding: 0;
}

.modal.danger .header {
  background: unset;
  color: var(--bs-danger);
  border-bottom: 0 solid var(--bs-danger);
  text-align: center;
  padding: 0;
}

.modal.warning .header {
  background: unset;
  color: var(--bs-warning);
  border-bottom: 0 solid var(--bs-warning);
  text-align: center;
  padding: 0;
}

.modal.info .header {
  background: unset;
  color: var(--bs-primary);
  border-bottom: 0 solid var(--bs-primary);
  text-align: center;
  padding: 0;
}

.modal.info .header:after,
.modal.danger .header:after,
.modal.warning .header:after,
.modal.success .header:after {
  content: "";
  background: #fff;
  width: 100%;
  height: 45px;
  position: absolute;
  left: 0;
  top: 30px;
  z-index: 0;
  border-radius: var(--bs-border-radius-xl) var(--bs-border-radius-xl) 0 0;
}

.modal.success .body {
  color: var(--bs-primary);
}

.modal.danger .body {
  color: var(--bs-danger);
}

.modal.info .body {
  color: var(--bs-blue);
}

.modal.warning .body {
  color: var(--bs-warning);
}

.modal.success .footer button.btn-success {
  border: 2px solid var(--bs-primary);
  background: var(--bs-primary-bg-subtle);
  color: var(--bs-primary);
  font-weight: bold;
}

.modal.success .footer button.btn-secondary {
  border: 2px solid var(--bs-secondary);
  background: var(--bs-secondary-bg-subtle);
  color: var(--bs-secondary);
  font-weight: bold;
}

.modal.success .footer button.btn-info {
  border: 2px solid var(--bs-info);
  background: var(--bs-info-bg-subtle);
  color: var(--bs-info);
  font-weight: bold;
}

.modal.warning .footer button.btn-success {
  border: 2px solid var(--bs-primary);
  background: var(--bs-primary-bg-subtle);
  color: var(--bs-primary);
  font-weight: bold;
}

.modal.warning .footer button.btn-secondary {
  border: 2px solid var(--bs-secondary);
  background: var(--bs-secondary-bg-subtle);
  color: var(--bs-secondary);
  font-weight: bold;
}

.modal.warning .footer button.btn-info {
  border: 2px solid var(--bs-info);
  background: var(--bs-info-bg-subtle);
  color: var(--bs-info);
  font-weight: bold;
}

.modal.warning .footer button.btn-warning {
  border: 2px solid var(--bs-warning);
  background: var(--bs-warning-bg-subtle);
  color: var(--bs-warning);
  font-weight: bold;
}

.modal.warning .footer button.btn-default {
  border: 2px solid var(--bs-warning);
  background: var(--bs-white);
  color: var(--bs-warning);
  font-weight: bold;
}

.modal.danger .footer button.btn-danger {
  border: 2px solid var(--bs-danger);
  background: var(--bs-danger-bg-subtle) !important;
  color: var(--bs-danger);
  font-weight: bold;
}

.modal.danger .footer button.btn-primary {
  background: var(--bs-primary);
}

.modal.info .footer button.btn-info {
  border: 2px solid var(--bs-info);
  background: var(--bs-info-bg-subtle);
  color: var(--bs-info);
  font-weight: bold;
}

.modal.info .footer button.btn-primary {
  background: var(--bs-primary);
  color: #fff;
  /* border: 2px solid var(--bs-primary); */
  /* background: var(--bs-primary-bg-subtle); */
  /* font-weight: bold; */
}

.modal.info .footer button.btn-danger {
  border: 2px solid var(--bs-danger);
  background: var(--bs-danger-bg-subtle);
  color: var(--bs-danger);
  font-weight: bold;
}

.modal.info .footer button.btn-secondary {
  border: 2px solid var(--bs-success);
  background: #ffffff;
  color: var(--bs-success);
  font-weight: bold;
}

.modal.info .footer button.btn-success {
  border: 2px solid var(--bs-success);
  background: var(--bs-success);
  color: #ffffff;
  font-weight: bold;
}

.modal .footer button:not(.w-100) {
  margin-left: 0.5rem;
}

.modal .footer button.w-100 {
  margin-top: 0.5rem;
}

.modal .body > h2.title {
  font-size: 19px;
  font-weight: 700;
  text-align: center;
}

.modal.show {
  display: block;
}

.modal.show2 {
  display: none;
  opacity: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  display: none;
  width: 100%;
  height: 100%;

  background-color: #00000060;
  backdrop-filter: blur(10px);
}

.modal-backdrop.show {
  display: block;
}

.modal-close img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.modal-close {
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 50%;
  z-index: 999;
}

img.modal-close {
  width: 22px !important;
  height: 22px !important;
  position: absolute;
  right: 10px;
  top: 10px;
}

.hide {
  display: none !important;
}

.course {
  border-radius: 4px;
  padding: 2px 10px;
  /*margin-left: 5px;*/
  margin-right: 5px;
  display: inline-block;
  width: 70px;
  text-align: center;
  color: #ffffff;
}

.fs-7 {
  font-size: 0.75rem !important;
}

.fs-8 {
  font-size: 0.65rem !important;
}

.coupon-legs {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.35rem;
  margin-right: -0.35rem;
}

.coupon-legs .col-2 {
  /*padding-left: 0.35rem;*/
  /*padding-right: 0.35rem;*/
}
.coupon-legs .col-12 {
  /*padding-left: 0.35rem;*/
  /*padding-right: 0.35rem;*/
}

.coupon-legs .wager-list {
  border-left: 1px solid var(--bs-gray-500);
  border-bottom: 1px solid var(--bs-gray-500);
}

.coupon-legs .col-2 .horse-no {
  border-bottom: 1px dashed #646bdd70;
  /*border-bottom: 1px dashed #FFFFFF;*/
}

.coupon-legs .col-2 .horse-no > div {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.coupon-legs .col-2 .horse-no {
  position: relative;
  background: #e7e9fc;
  padding: 2px;
  font-size: 13px;
}

.coupon-legs .col-2 .horse-no .stablemate:before {
  content: " ";
  display: block;
  width: 1px;
  height: 100%;
  background: var(--bs-gray-500);
  position: absolute;
  top: 0;
  margin-left: -10px;
}

.coupon-legs .col-2 .horse-no img {
  margin-left: 10px;
}

.coupon-legs .col-2 .horse-no .stablemate {
  border-top: 1px solid var(--bs-gray-500);
  display: block;
  margin-left: -10px;
  padding-left: 10px;
}

.coupon-legs .col-2 .horse-no.text-success {
  font-weight: 600 !important;
}

.coupon-legs .col-2 .horse-header .w-25,
.tjk .agf-tables .col-2 .horse-no .w-25 {
  width: 30px !important;
}

.coupon-legs .col-2 .horse-header .w-75,
.tjk .agf-tables .col-2 .horse-no .w-75 {
  text-align: left !important;
  padding-left: 10px;
  width: calc(100% - 30px) !important;
}

.coupon-legs .col-2 .horse-no:last-child {
  padding-bottom: 15px;

  --mask: radial-gradient(5.59px at 50% calc(100% - 7.5px), #000 99%, #0000 101%) calc(50% - 10px) 0 / 20px 100%,
    radial-gradient(5.59px at 50% calc(100% + 2.5px), #0000 99%, #000 101%) 50% calc(100% - 5px) / 20px 100% repeat-x;
  -webkit-mask: var(--mask);
  mask: var(--mask);
}

.coupon-legs .run-no {
  position: relative;
  padding: 5px 5px 5px 5px;
  text-align: center;
  background: #646bdd60;
  font-weight: 600;
  /* --mask: radial-gradient(5.59px at 50% 7.5px, #000 99%, #0000 101%) calc(50% - 10px) 0 / 20px 100%, radial-gradient(5.59px at 50% -2.5px, #0000 99%, #000 101%) 50% 5px / 20px 100% repeat-x; */
  /* mask: var(--mask); */
  /* border-bottom: 2px dashed #FFFFFF; */
  border-radius: 10px;
}

.coupon-legs .col-2:nth-child(odd) .run-no {
  background: #646bdd60;
}

.coupon-legs > .w-100:nth-child(even) .run-no {
  background: #646bdd30;
}

.invert-color {
  filter: invert(1);
}

.cursor-pointer {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.text-primary {
  --bs-text-opacity: 1;
  color: var(--bs-primary) !important;
}
table.coupon-detail {
  margin-bottom: 5px;
  border: 0;
  --bs-border-width: 0;
  --bs-table-striped-bg: #e7e9fc;
}

table.coupon-detail > tbody > tr:nth-of-type(odd) > *:first-child {
  border-radius: 10px 0 0 10px;
}

table.coupon-detail > tbody > tr:nth-of-type(odd) > *:last-child {
  border-radius: 0 10px 10px 0;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container input,
.input {
  border-radius: var(--bs-border-radius);
  border: 1px solid #1e3d98;
  padding: 0.5rem;
  background: #d0d3f560;
  width: 100%;
  height: 45px;
}

.react-datepicker__input-container:after {
  content: "";
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  width: 20px;
  background: url(../src/assets/feather/calendar.svg) center / contain no-repeat;
}

.possibles-table {
  padding: 5px;
}

.possibles-table .runs {
  padding: 10px;
}

.possibles-table .runs .caption {
  background: #646bdd;
  font-weight: 600;
  color: #ffffff;
  border-radius: var(--bs-border-radius-lg);
  padding: 10px;
}

.possibles-table .runs .line span {
  font-weight: normal;
}

.possibles-table .runs .line {
  border-radius: var(--bs-border-radius);
  margin-top: 5px;
  border: 1px solid transparent;
  font-weight: 600;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.possibles-table .runs .line.high {
  background: var(--bs-warning);
  border: 1px solid var(--bs-warning);
}

.possibles-table .runs .line.low {
  background: var(--bs-warning-border-subtle);
  border: 1px solid var(--bs-warning);
}

.possibles-table .runs .line:nth-child(odd) {
  background: #e7e9fc;
}

.possibles-table .total-agf {
  /*!*position: absolute;*!*/
  /*top: 15px;*/
  /*right: 0;*/
  /*transform: rotate(-90deg);*/
  /*width: 40px;*/
  /*text-align: center;*/
  /*!*height: 100%;*!*/
  writing-mode: vertical-lr;
  text-orientation: mixed;
  background-color: #646bdd;
  color: #ffffff;
  padding: 5px;
  position: absolute;
  height: 60px;
  top: 0px;
  width: 20px;
  z-index: 2;
  right: 0;
  text-align: center;
  line-height: 12px;
  border-bottom-right-radius: var(--bs-border-radius);
  border-top-right-radius: var(--bs-border-radius);
  font-size: 12px;
}
.possibles-table .stablemate-number {
  background-color: #e7e9fc;
  padding: 5px;
  position: absolute;
  height: 60.8px;
  top: -1px;
  width: 22px;
  z-index: 2;
  left: -1px;
  text-align: center;
  border-bottom-left-radius: var(--bs-border-radius);
  border-top-left-radius: var(--bs-border-radius);
  display: flex;
  justify-content: center;
  align-items: center;
}

.possibles-table .runs img {
  margin-left: 10px;
  /*margin-right: 10px;*/
}

.possibles-table .runs .stablemate {
  display: block;
  /*margin-left: -10px;*/
  padding-left: 0;
}

input.code-input:not(:last-child) {
  margin-right: calc(5px * 2);
}

.horse-added-agf {
  background: #646bdd60 !important;
}

.code-input-seperator {
  width: 10px;
  text-align: center;
  padding-top: 7px;
  margin-right: 10px;
}

.code-input {
  font-size: 1.5em;
  width: 100%;
  text-align: center;
  flex: 1 0 1em;
  border-radius: 10px;
  border: 1px solid var(--bs-gray-600);
}

.code-input::-webkit-outer-spin-button,
.code-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.code-input[type="number"] {
  -moz-appearance: textfield;
}

#id_datalist_loadmore {
  display: none;
}

.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 8px solid #514b82;
  animation: l20-1 0.8s infinite linear alternate, l20-2 1.6s infinite linear;

  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: none;
}

@keyframes l20-1 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }
  12.5% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  }
  62.5% {
    clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
  }
}

@keyframes l20-2 {
  0% {
    transform: scaleY(1) rotate(0deg);
  }
  49.99% {
    transform: scaleY(1) rotate(135deg);
  }
  50% {
    transform: scaleY(-1) rotate(0deg);
  }
  100% {
    transform: scaleY(-1) rotate(-135deg);
  }
}

.skeleton {
}

@keyframes skeleton-shine {
  to {
    background-position-x: -200%;
  }
}

.betslip-body,
.page-widget,
.betting-run-tabs {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.ReactModal__Content.default .modal-body {
  max-height: calc(100vh - 180px);
  overflow: scroll;
  padding-bottom: 40px;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.ReactModal__Content.default {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  max-width: calc(100% - 80px);
  width: 370px;
  padding: 1.5rem;
  max-height: calc(100vh - 100px);
  transform: translate(-50%, -50%);
  background: var(--bs-white);
  position: absolute;
  overflow: auto;
  outline: 0;
  border-radius: var(--bs-border-radius);
}

.ReactModal__Content.default h2 {
  font-size: 18px;
  font-weight: 700;
  height: 30px;
  margin: 0;
}

.hint-text h3 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}

.hint-text p {
  font-size: 12px;
  margin-bottom: 10px;
}

.password-code-input {
  font-size: 1.5rem;
  letter-spacing: 1rem;
  text-align: center;
}

.tjkIframe {
  font-size: 12px;
  padding: 0;
}

.tjkIframe a {
  text-decoration: none;
}

.tjkIframe > .grid_10 {
  width: 50%;
  float: left;
}

.tjkIframe > .grid_14 {
  width: 50%;
  float: left;
}

.tjkIframe > .grid_14 .grid_8 .value {
  font-weight: 600;
}

.tjkIframe > .grid_14 .grid_8 .key {
  width: 40%;
  position: relative;
  display: inline-block;
  margin-bottom: 3px;
  vertical-align: top;
  font-weight: bold;
  font-size: 12px;
}

.tjkIframe > .grid_14 .grid_8 {
  width: 60%;
  float: left;
}

.tjkIframe > .grid_14 .grid_6 .value {
  width: 45% !important;
  float: right;
  font-weight: 600;
}

.tjkIframe > .grid_14 .grid_6 .key {
  width: 45% !important;
  display: inline-block;
}

.tjkIframe > .grid_14 .grid_6 {
  width: 40%;
  float: left;
  padding-right: 15px;
}

.tjkIframe #queryTable {
  width: 100%;
  border: 0;
}

.tjkIframe #queryTable thead th:nth-child(odd) {
  background: var(--bs-gray-100);
}

.tjkIframe #queryTable thead th {
  background: var(--bs-gray-300);
  text-align: center;
  padding: 10px 3px;
}

.tjkIframe #queryTable tbody tr td:nth-child(even) {
  background: var(--bs-gray-100);
}

.tjkIframe #queryTable tbody tr td {
  border-bottom: 1px solid var(--bs-gray-300);
}

.tjkIframe #queryTable.horseStats tbody tr td:nth-child(15),
.tjkIframe #queryTable.horseStats thead tr th:nth-child(15) {
  display: none;
}

.tjkIframe #queryTable.horseStats tbody tr td:nth-child(16),
.tjkIframe #queryTable.horseStats thead tr th:nth-child(16) {
  display: none;
}

.tjkIframe #queryTable.horseStats tbody tr td:nth-child(13),
.tjkIframe #queryTable.horseStats thead tr th:nth-child(13) {
  display: none;
}

.tjkIframe #queryTable.jockeyStats tbody tr td:first-child,
.tjkIframe #queryTable.jockeyStats thead tr th:first-child {
  display: none;
}

.tjkIframe #queryTable.jockeyStats td {
  min-width: 35px;
  width: max-content;
  white-space: nowrap;
  padding: 4px;
}

.tjkIframe #queryTable .hidable {
  display: none;
}

.tjkIframe #queryTable tbody tr td {
  padding: 0 3px;
  text-align: center;
  font-size: 12px;
}

#tjkLoading .lds-roller,
#accountPageLoading .lds-roller {
  top: 50vh;
}

#tjkLoading,
#accountPageLoading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--bs-white);
  opacity: 0.7;
  z-index: 9999999999999;
  display: none;
}

.visible-sm {
  display: none;
}

.hippodrome-container {
  position: sticky;
  top: 0;
  z-index: 1;
}

@media (max-width: 985px) {
  .modal {
    width: calc(100% - 30px) !important;
  }
  .hidden-sm {
    display: none !important;
  }

  .visible-sm {
    display: block;
  }

  .slider-wrapper .slide img {
    width: 100% !important;
    height: 150px !important;
  }

  header {
    position: unset !important;
    /*background: #1E3D98;*/
  }

  header nav {
    width: 100% !important;
  }

  header nav > a.register {
  }

  header nav > a.login,
  header nav > a.register {
    float: right !important;
    margin-top: 5px !important;
    margin-right: 5px !important;
  }

  header .user-balance {
    float: right !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    min-width: 65px;
    padding: 4px 5px !important;
  }
  header .menu-button {
    float: right !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    min-width: 25px;
    background: var(--bs-warning);
    height: 50px;
    padding: 5px 10px;
  }

  header .user-notification {
    float: right !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    padding: 17px 5px 0 5px !important;
  }
  header .user-tickets {
    float: right !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    padding: 17px 5px 0 5px !important;
  }

  header .user-profile {
    width: max-content !important;
    max-width: 80px !important;
    float: right !important;
    min-width: 40px;
  }

  header .member-dropdown-menu {
    width: 229px !important;
  }
  .nav-logo {
    height: 34px !important;
    margin-top: 8px !important;
    margin-left: 8px !important;
  }

  .hippodrome-container {
    margin-top: 10px !important;
    position: relative;
    top: unset;
    z-index: 1;
  }

  .react-datepicker__input-container input,
  .input {
    height: 35px;
  }

  .hippodrome-widgets .swiper-pagination-bullet-active {
    background: #adb2ff !important;
  }

  .hippodrome-widgets .swiper {
    padding-bottom: 25px;
  }

  .hippodrome-widgets .swiper-pagination {
    bottom: 0 !important;
    top: unset !important;
  }

  .hippodrome-widgets .swiper-wrapper .swiper-slide {
    background: #fff;
    border-radius: var(--bs-border-radius-xl);
  }

  .hippodrome-widgets {
    margin-top: unset !important;
    padding: 0 !important;
    background: unset !important;
    box-shadow: unset !important;
  }

  .hippodrome-widgets .item.active {
    background: unset !important;
  }

  .hippodrome-widgets .item {
    padding: 10px !important;
    background: unset !important;
  }

  .col-data .nav.nav-tabs {
    width: max-content;
    display: flex;
    min-width: 100%;
  }

  .nav-item > svg {
    width: 20px !important;
    height: 40px !important;
  }

  .nav-tabs .nav-item .nav-link {
    padding: 10px 15px;
  }

  .nav-tabs .nav-item .nav-link.ayak {
    padding: 10px 1.2rem;
  }

  .nav-tabs .nav-item:first-child.active .nav-link {
    padding-left: 15px !important;
  }

  .nav-tabs .nav-item.active .nav-link {
    padding: 10px 0 !important;
    text-wrap: nowrap;
  }
  .nav-tabs .nav-item.active .nav-link.ayak {
    padding: 10px 1.2rem !important;
  }

  .badge-tabs > .nav-tabs .nav-item.active .nav-link.show-badge {
    padding: 10px 10px 10px 0 !important;
  }
  .badge-tabs > .nav-tabs .nav-item .nav-link.show-badge {
    padding: 10px 10px 10px 10px !important;
  }
  .badge-tabs > .nav-tabs .nav-item .nav-link.show-badge.ayak {
    padding: 10px 1.2rem 10px 1.2rem !important;
  }
  .badge-tabs > .nav-tabs .nav-item:first-child.active .nav-link {
    padding-left: 15px !important;
  }

  /*.col-betslip .betslip .body{*/
  /*  height: calc(100vh - 410px);*/
  /*}*/
  .betslip .body {
    display: block !important;
  }

  .betslip ul {
    width: 100% !important;
  }

  .betslip ul li.system:not(.horse-added),
  .betslip ul li.emptyHorse {
    display: none !important;
  }

  .betslip ul li {
    width: 100% !important;
    height: auto !important;
    padding: 5px;
    text-align: left !important;

    justify-content: unset !important;
    align-items: unset !important;
    display: block !important;
  }

  /*.betslip ul{*/
  /*  width: 100% !important;*/
  /*}*/
  /*.betslip ul li{*/
  /*  width: 100% !important;*/
  /*  height: auto !important;*/
  /*  aspect-ratio: 1 / 1;*/
  /*}*/
  .col-betslip {
    width: 100% !important;
  }

  .col-data {
    width: 100% !important;
  }

  .bettingtype-options {
    margin-bottom: 0.5rem !important;
  }
  .bettingtype-options select {
    height: 35px;
    padding: 0.25rem;
  }

  .btn-muhtemeller,
  .btn-agf {
    height: 35px;
    font-size: 11px;
  }

  .possibles-table .col .caption {
    padding: 5px;
    font-size: 12px;
  }

  .tjkIframe > .grid_10 {
    width: 100%;
    float: left;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .tjkIframe > .grid_14 {
    width: 100%;
    float: left;
  }

  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    padding-left: 5px;
    padding-right: 5px;
  }

  .row > * {
    padding-left: 5px;
    padding-right: 5px;
  }
  .row {
    margin-left: -5px;
    margin-right: -5px;
  }
}

/*@media (max-width: 1408px) and (min-width: 992px) {*/
/*    html {*/
/*        zoom: 0.8;*/
/*    }*/
/*}*/

.tab-scroll .nav-tabs {
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
}

.tab-scroll .nav-tabs .nav-item {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  align-content: space-around;
  flex-wrap: nowrap;
}

.home-header {
  background-color: #d0d3f5;
  border-radius: 10px;
  text-align: center;
  color: #1e3d98;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 4px;
  padding: 10px 0;
}

.tipster-comment {
  display: flex;
  border: 1px solid #e7e9fc;
  margin-bottom: 10px;
  border-radius: 10px;
}
.tipster-comment:nth-child(odd) {
  background: #e7e9fc;
}
.tipster-comment div:first-child {
  width: 80px;
  min-height: 80px;
  text-align: center;
  padding: 10px;
  font-weight: 600;
  text-overflow: ellipsis;
}
.tipster-comment div:last-child {
  width: calc(100% - 80px);
  padding: 10px;
}

.tipsters-list li {
  margin-right: 5px;
  color: #1e3d98;
  border: 1px solid #1e3d98;
  background-color: #d0d3f5;
  border-radius: 6px;
  padding: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
  text-wrap: nowrap;
  cursor: pointer;
}

.tipsters-list li > .avatar {
  border-radius: 50%;
  width: 33px;
  min-width: 33px;
  height: 33px;
  min-height: 33px;
  object-fit: cover;
  float: left;
  margin-right: 10px;
  border: 2px solid #ffffff;
  object-position: top;
}
.tipsters-list li.active {
  color: #ffffff;
  border: 1px solid #1e3d98;
  background-color: #1e3d98;
}
.tipsters-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  clear: both;
  width: 100%;
  margin-bottom: 20px;
  overflow: scroll;
}

.tipster-info {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  font-size: 21px;
  font-weight: 500;
}
.tipster-info > div > span {
  font-weight: normal;
  font-size: 14px;
}

.tipster-info > .avatar {
  border-radius: 50%;
  width: 52px;
  min-width: 52px;
  height: 52px;
  min-height: 52px;
  object-fit: cover;
  float: left;
  margin-right: 10px;
  border: 2px solid #ffffff;
  object-position: top;
}
/*.tipsters-list li.a*/

[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.tab-badge {
  position: absolute;
  background: #646bdd;
  color: #ffffff;
  border-radius: 50%;
  padding: 0 5px;
  top: 1px;
  font-size: 12px;
}

.show-badge {
  padding-right: 5px !important;
}

.betting-navigation-tab {
  padding: 0.75rem 0;
}

.betting-navigation-tab > .row > .col {
  border-right: 1px solid #9ca0e9;
}

.betting-navigation-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
}

.betting-navigation-item.active {
  background-color: #9ca0e9;
  border-radius: 15px;
}

.betting-navigation-tab > .row > .col:last-child {
  border-right: 0;
}

.betting-navigation-item > a {
  font-size: 16px;
  margin-bottom: 0;
  color: #1e3d98;
  font-weight: 600;
  text-decoration: none;
}

.betting-navigation-item.active > a {
  background-color: #9ca0e9;
  border-radius: 15px;
  color: #ffffff;
}

.horse-result {
  width: calc(100%);
  background: #d7daf4;
  padding: 5px 10px;
}

.customize-header {
  list-style: none;
}
.customize-header > li {
  margin-bottom: 0.25rem;
}
.customize-header > li > div > label {
  font-size: 14px;
  font-weight: 500;
}

.customize-header-checkbox {
  accent-color: #1e3d98;
  border: 2px solid #1e3d98;
  height: 17px;
  margin-right: 10px;
  margin-top: 0;
  width: 17px;
}

.video-wrapper {
  position: relative;
  display: inline-block;
}

.blurEffect {
  -webkit-filter: blur(7px);
  -o-filter: blur(7px);
  -moz-filter: blur(7px);
  -ms-filter: blur(7px);
  filter: blur(7px);
}

.video-content {
  position: absolute;
  display: inline-block;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  color: #fff;
  width: 100%;
  text-align: center;
  z-index: 999;
}

.video-content > h1 {
  padding-left: 0;
  margin-top: 1rem;
}

.play {
  font-size: 44px;
  cursor: pointer;
  border: 1px solid #fff;
  display: inline-block;
  text-align: center;
  padding: 5px 25px;
  border-radius: 10px;
}

.play:hover {
  color: red;
}

.forget-password-card {
  border: 1px solid var(--bs-primary-bg-subtle);
  padding: 0.25rem 0.25rem;
  border-radius: 10px;
}

.forget-password-card:hover {
  background: var(--bs-primary-bg-subtle);
}

.betslip-select {
  position: absolute;
  right: 0;
  z-index: 0;
  width: calc(100% - 15px);
  background: white;
  height: 45px;
}

.betslip-input {
  width: calc(100% - 50px) !important;
  z-index: 1;
  position: relative;
  border: none !important;
  left: 20px;
  padding: 0.4rem !important;
  outline-color: #ffffff;
  height: 38px;
}

.betslip-misli {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  height: 40px;
}

.ticket-table > tbody,
td,
tfoot,
th,
thead,
tr {
  vertical-align: middle;
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-without-arrow .dropdown-toggle::after {
  display: none; /* Hide the default dropdown arrow */
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropstart .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.rapid-menu.show{
  height: 90px;
  overflow: scroll;
}

#gameIFrame {
  width: 100% !important;
  height: 100% !important;
  border: none;
  text-align: center;
  background-color: Black;
}

.iframeContainer {
  height: calc(100vh - 50px);
  border: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

@media (max-width: 968px) {
  .iframeContainer {
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;
    bottom: 0;
    right: 0;
    border: none;
    margin: 0;
    padding: 0;
    overflow: visible;
    z-index: 1;
    /*z-index: 999999;*/
  }
}

canvas {
  height: 100% !important;
}

.btn-outline-quantity::placeholder {
  color: #646bdd;
}


.text-wrap {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.text-normal-wrap{
  overflow: hidden !important;
  white-space: normal !important;
}
