.home-shortcuts {
  margin-bottom: 1rem;
  /*padding: 0 0.5rem;*/
}
.home-shortcuts > .col-3 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.home-shortcuts .item a {
  text-decoration: none;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}

.home-shortcuts .item img {
  width: 40px;
}
.home-shortcuts .item span {
  /*display: block;*/
  float: right;
  width: 35px;
  height: 100%;
  background: #646bdd;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
}
.home-shortcuts .item span:after {
  font-family: swiper-icons;
  content: "next";
  color: #ffffff;
}
.home-shortcuts .item div {
  width: calc(100%);
  margin-left: 15px;
  margin-right: 0;
  margin-bottom: 0;
}
.home-shortcuts .item h2 {
  color: #1e3d98;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 0;
}

.home-shortcuts .item {
  background: var(--bs-white);
  padding: 15px;
  border-radius: var(--bs-border-radius-xl);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  position: relative;
  height: 120px;
}

.home-shortcuts .item.skeleton {
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #ffffff 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1.5s skeleton-shine linear infinite;
}

.home-shortcuts .mobile-item {
  height: 90px;
  padding: 5px;
}
.home-shortcuts .mobile-item img {
  width: 20px;
}

.home-shortcuts .mobile-item h2 {
  margin-top: 0.5rem;
  color: #1e3d98;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0;
}

.ticket-widget.skeleton .header {
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1.5s skeleton-shine linear infinite;
  height: 75px;
  margin-right: 5px;
}

.ticket-widget.skeleton .body {
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1.5s skeleton-shine linear infinite;
  height: 270px;
  margin-right: 5px;
  margin-bottom: 3px;
}

.ticket-widget.skeleton .footer {
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1.5s skeleton-shine linear infinite;
  height: 80px;
  margin-right: 5px;
}

.tipster-tickets-widget {
  position: relative;
  z-index: 0;
}

.tipster-tickets-widget .swiper-button-prev,
.tipster-tickets-widget .swiper-button-next {
  height: 50px;
  background: #9ca0e9;
  color: #ffffff;
  font-weight: bold;
  margin-top: 0;

  width: 50px;
}
.tipster-tickets-widget .swiper-button-prev {
  border-radius: var(--bs-border-radius-xl);
  left: 0;
}
.tipster-tickets-widget .swiper-button-next {
  border-radius: var(--bs-border-radius-xl);
  right: 0;
}
.tipster-tickets-widget .swiper-button-prev:after {
  content: "prev";
  font-size: 16px;
}
.tipster-tickets-widget .swiper-button-next:after {
  content: "next";
  font-size: 16px;
}

.tipster-tickets-widget .swiper-custom-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tipster-tickets-widget .swiper-custom-pagination > .swiper-pagination-bullet {
  margin: 10px 5px 0 5px;
}

.home-link {
  background: #ffffff;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home-link > a {
  padding: 5px 5px;
  border-radius: var(--bs-border-radius);
  margin-left: 5px;
  margin-right: 5px;
  text-decoration: none;
  color: #1e3d98;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}
.home-link > a img {
  margin-right: 10px;
  width: 18px;
}
.home-link > div > img {
  filter: invert(8%) sepia(90%) saturate(4287%) hue-rotate(260deg) brightness(115%) contrast(120%);
}

.home-raffle-slider {
  background: #ffffff;
  border-radius: 20px;
  background-color: "#9CA0E9";
  margin: 1rem 0rem;
}
.home-raffle-slider > .swiper {
  padding: 10px;
}

.home-raffle-footer {
  background: #adb2ff;
  padding: 20px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.home-raffle-footer > p {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 16px;
}

.home-raffle-tab-item {
  /* background: var(--bs-white); */
  padding: 10px;
  margin: 0 15px;
  border-radius: var(--bs-border-radius-xl);
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: relative;
  background-color: #bec8f7;
}

.home-raffle-tab-item.border {
  height: 70px;
  margin: 0;
}

.home-raffle-tab-item > img {
  height: 35px;
  /*opacity: 0.5;*/
  margin-right: 0.25rem;
}

.home-raffle-tab-item.selected > img {
  opacity: 1;
}

.home-raffle-tab-item > div {
  /*opacity: 0.5;*/
}

.home-raffle-tab-item.selected > div {
  opacity: 1;
}

.home-raffle-tab-item.selected {
  background-color: #bec8f7;
  opacity: 1;
}

.home-raffle-tab-item h2 {
  margin-top: 0.5rem;
  color: #6f76e6;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
  text-align: center;
}

.home-raffle-tab-item-badge {
  position: absolute;
  right: -7.5%;
  background: #ff3a3a;
  border-radius: 12px;
  padding: 0 5px;
  font-size: 11px;
  color: #ffffff;
  font-weight: 700;
}

/*.home-raffle-slider .swiper-button-prev, .swiper-button-next{*/
/*    width: 21px !important;*/
/*    height: 21px !important;*/
/*    font-size:21px !important;*/
/*}*/
/*.home-raffle-slider .swiper-button-prev:after,.home-raffle-slider .swiper-button-next:after{*/
/*    font-size:21px !important;*/
/*}*/
