.slider-wrapper .slide .caption p{
    color: #FFF;
    font-size: 14px;
}
.slider-wrapper .slide .caption h3{
    color: var(--bs-warning);
    font-weight: 700;
    font-size: 46px;
    margin-bottom: 0;
}
.slider-wrapper .slide .caption h2{
    color: #FFF;
    font-weight: 400;
    font-size: 28px;
    margin-bottom: 0;
}
.slider-wrapper .slide .caption{
    right: 0;
    position: absolute;
    top: 0;
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
}
.slider-wrapper .slide{
    position: relative;

}
.slider-wrapper .slide img {
    /*height: 520px;*/
    width: 100%;
    object-fit: cover;
    border-radius: 25px;
}

.slider-wrapper{
    /*background: #2a3f96;*/
    padding-right: 0;
}



.slider-wrapper .swiper-button-next,.slider-wrapper .swiper-button-prev{
    color: #FFF;
}
.slider-wrapper .swiper-pagination-bullet-active{
    width: 20px;
    border-radius: 5px;
}
.slider-wrapper .swiper-pagination-bullet{
    background: #FFFFFF;
}
.slider-wrapper .swiper-pagination{
    /*top:calc(100% - 100px) !important;*/
}
.slider-wrapper .swiper{
    border-radius: 25px;
}

@media (max-width: 968px) {
    .slider-wrapper .slide .caption{
        top:15px;
        width: 52.5%;
        left: 45vw;
    }
    .slider-wrapper .slide .caption h2{
        font-size: 14px;
    }
    .slider-wrapper .slide .caption h3{
        font-size: 16px;
    }
    .slider-wrapper .slide .caption p{
        font-size: 12px;
    }
    .slider-wrapper .swiper-pagination{
        /*top:calc(100% - 45px) !important;*/
    }
    .slider-wrapper .slide img{
        border-radius: 0;
    }
    .slider-wrapper{

        padding-left: 0;
    }
    .slider-wrapper .swiper{
        border-radius: 0px;
    }
}


.right-slide > div > p > a > img {
    width: 98%;
    height: auto;
    border-radius: 25px;
}

.right-slide > div > a > img {
    width: 98%;
    height: auto;
    border-radius: 25px;
}
.right-slide > div > p > img {
    width: 98%;
    height: auto;
    border-radius: 25px;
}
