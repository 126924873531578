.betslip{
    background: var(--bs-white);
    border-radius: var(--bs-border-radius-xl);
    padding: 1rem;
}
.betslip h3{
    font-size: 1rem;
    font-weight: normal;
    margin-bottom: 0.25rem;
}
.betslip h2{
    font-size: 1rem;
    font-weight: 600;
    color: #1E3D98;
    margin-bottom: 0;
}

.betslip .body{
    display: flex;
    margin-top: 0.5rem;
}

.betslip ul{
    list-style: none;
    margin: 0;
    padding: 0;
    margin-right: 2px;
}

.agf-horse{
    border: 1px solid #198754 ;
    color: #198754;
}

.betslip ul li{
    width: 29px;
    height: 30px;
    /*border: 1px solid #646BDD75;*/
    background: #D0D3F5;
    border-radius: 5px;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 2px;
    display: flex;
    font-weight: 500;
    cursor: pointer;
}
.betslip ul:not(.emptyColumn) li.column-no{
    background: var(--bs-warning);
    color: var(--bs-dark);
    font-weight: 600;
}

.betslip ul.emptyColumn li.column-no{
    background: var(--bs-gray-200);
    color: var(--bs-gray-500);
    cursor: not-allowed;
}

.betslip ul:not(.emptyColumn) li.system, .betslip ul li.all {
    background: #646BDD75;
    color: var(--bs-dark);
}

.betslip ul.emptyColumn li.system, .betslip ul.emptyColumn li.all, .betslip ul li.emptyHorse{
    background: var(--bs-gray-200);
    color: var(--bs-gray-500);
    cursor: not-allowed;
}

.betslip ul li.horse-added{
    background: #646BDD;
    color: var(--bs-white);
    font-weight: bold;
}


.betslip ul li.system.horse-added{
    background: var(--bs-danger) !important;
    color: var(--bs-white) !important;
    font-weight: bold;
}

.betslip ul li.all.selected-all{
    background: var(--bs-danger) !important;
    color: var(--bs-white) !important;
    font-weight: bold;
}


.betslip .footer{
    margin-top: 1rem;
}
.betslip .footer > div span{
    text-align: right;
    display: block;
    width: 60%;
    font-weight: 700;
    font-size: 1rem;
}

.betslip .footer > div label{
    width: 40%;
    font-weight: 600;
    display: flex;
    justify-content: left;
    align-items: center;
}
.betslip .footer > div input:not([type=checkbox]){
    width: 60%;
    border-radius: 5px;
    border: 1px solid #646BDD75;
    padding: 0.5rem;

}
.betslip .footer > div input[type=checkbox]{
    margin-left: auto;
}

.betslip .footer > div{
    display: flex;
    border-top: 1px solid #646BDD75;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.betslip .footer > div.actions{
    display: flex;
    justify-content: space-between;
}
.betslip .footer > div button.button-clear{
    width: 20%;
    margin-right: 5px;
    background: var(--bs-danger);
}
.betslip .footer > div button.button-clear img{
    filter: invert(1);
}
.betslip .footer > div button.button-save{
    width: 20%;
    margin-right: 5px;
    background: var(--bs-gray-500);
}
.footer > div button.button-play{
    width: 60%;
    background: var(--bs-warning);
}
.footer > div button.button-play:disabled{
    background: var(--bs-gray-500);
}
.footer > div button.button-play.share{
    width: 60%;
    background: var(--bs-primary);
    color: #FFFFFF;
}
.betslip .footer > div button{
    border-radius: 5px;
    border: none;
    font-weight: 600;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.betslip .footer > div button img{
    width: 20px;
    height: 20px;
}



.sortable-horse-helper{
    background: #646BDD;
    color: var(--bs-white);
    font-weight: bold;
    list-style: none;
    margin: 0;
    display: flex !important;
    justify-content: left;
    align-items: center;
    border-radius: 5px;
    padding-left: 10px;
}

.sortable-horse-helper span{
    display: block;
    width: calc(100% - 30px);
    text-align: left;
}


.betslip .sortable-horses li span{
    display: block;
    text-align: left;
    font-size: 12px;
}
.betslip .sortable-horses li{
    width: 100%;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center;
    padding-left: 5px;
}
.betslip .sortable-horses{
    width: 100%;
}



@media (max-width: 968px) {
    .betslip-body{
        height: 42vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }
}


.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 32px;

}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 25px;
    width: 25px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #646BDD;
}

input:focus + .slider {
    box-shadow: 0 0 1px #646BDD;
}

input:checked + .slider:before {
    -webkit-transform: translateX(25px);
    -ms-transform: translateX(25px);
    transform: translateX(25px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
