@property --p {
    syntax: "<number>";
    inherits: true;
    initial-value: 0;
  }
  @property --ticket-mask-color {
    syntax: "<string>";
    inherits: true;
    initial-value: "";
  }

  .ticket-card .pie {
    --p: 20;
    --b: 22px;
    --c: darkred;
    --w: 150px;

    width: var(--w);
    aspect-ratio: 1;
    position: relative;
    display: inline-grid;
    margin: 5px;
    place-content: center;
    font-size: 15px;
    line-height: 12px;
    text-align: center;
    font-weight: bold;
  }

  .ticket-card .pie:before,
  .ticket-card .pie:after {
    content: "";
    position: absolute;
    border-radius: 50%;
  }
  .ticket-card .pie:before {
    inset: 0;
    background: radial-gradient(farthest-side, var(--c) 98%, #0000) top/var(--b)
        var(--b) no-repeat,
      conic-gradient(var(--c) calc(var(--p) * 1%), #e9eff4 0);
    -webkit-mask: radial-gradient(
      farthest-side,
      #0000 calc(99% - var(--b)),
      #000 calc(100% - var(--b))
    );
    mask: radial-gradient(
      farthest-side,
      #0000 calc(99% - var(--b)),
      #000 calc(100% - var(--b))
    );
  }
  .ticket-card .pie:after {
    inset: calc(50% - var(--b) / 2);
    background: var(--c);
    transform: rotate(calc(var(--p) * 3.6deg))
      translateY(calc(50% - var(--w) / 2));
  }
  .ticket-card .pie span {
    font-size: 9px;
  }
  .ticket-card .pie.animate {
    animation: p 1s 0.5s both;
  }
  .ticket-card .pie.no-round:before {
    background-size: 0 0, auto;
  }
  .ticket-card .pie.no-round:after {
    content: none;
  }

  .ticket-card {
    /*background: var(--bs-white);*/
    --ticket-mask-color: ;
    filter: drop-shadow(0 0 6px #cfe6e7);
    transition: 300ms;
  }

  .ticked-card:hover {
    filter: drop-shadow(0 0 6px #8389E4) !important;
    transition: 300ms;
  }

  .drawers-slider-container-top .ticket-card {
    padding: 8px !important;
    margin: 2px !important;
    /* border: 1px dotted #cfe6e7 !important; */
    border-radius: var(--bs-border-radius) !important;
    filter: drop-shadow(0 0 6px #cfe6e7);
    transition: 300ms;
  }

  .drawers-slider-container-top .ticket-card:hover {
    /* filter: drop-shadow(0 0 6px #8389E4) !important;
    transition: 300ms; */
  }

  .drawers-slider-container-bottom .ticket-card {
    padding: 8px !important;
    margin: 2px !important;
    /* border: 1px dotted #cfe6e7 !important; */
    border-radius: var(--bs-border-radius) !important;
    filter: drop-shadow(0 0 6px #cfe6e7);
    transition: 300ms;
  }

  .drawers-slider-container-bottom .ticket-card:hover {
    /* filter: drop-shadow(0 0 6px #8389E4) !important;
    transition: 300ms; */
  }
  .ticket-card .head-cuteffect {
    background: var(--bs-white);
  }
  .ticket-card .head-cuteffect .cutEffect span {
    background: var(--ps-body-bg) !important;
  }
  .ticket-card .head-cuteffect h2 {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    padding: 10px 5px;
  }
  .ticket-card .body:after {
    content: "";
    position: absolute;
    width: 20px;

    height: 40px;
    border-radius: 20px 0px 0px 20px;
    right: -1px;
    bottom: -20px;
    z-index: 1;
  }
  .ticket-card .body:before {
    content: "";
    position: absolute;
    width: 20px;

    height: 40px;
    border-radius: 0px 20px 20px 0px;
    left: -1px;
    bottom: -20px;
    z-index: 1;
  }

  .ticket-card .ticket-left > div.label {
    position: relative;
    z-index: 1;
    text-align: left;
    padding: 1px 5px;
    line-height: 15px;
  }
  .ticket-card .ticket-left > div.bar {
    background: var(--c);
    height: 22px;
    position: absolute;
    width: calc(1% * var(--p));
    top: 0;
    animation: p 1s 0.5s both;
  }
  .ticket-card .ticket-left {
    background: var(--bs-gray-100);
    font-size: 11px;
    border-radius: var(--bs-border-radius);
    overflow: hidden;
    margin-left: 0.75rem;
    margin-right: 0.75rem;

    bottom: 10px;
    position: absolute;
    width: calc(100% - 2.5rem);
  }

  @keyframes p {
    from {
      --p: 0;
    }
  }

  .ticket-card .head > div.circle-sold {
    background: linear-gradient(180deg, #ffffff00 20px, #ffffff 20px);
  }
  .ticket-card .head > div.circle-sold > div {
    width: 70px;
    background: var(--bs-white);
    border-radius: 50% 50% 0 0;
  }
  .ticket-card .head > div:not(.circle-sold) {
    width: calc(50% - 35px);
    text-align: center;
    background: var(--bs-white);
    top: 20px;
    height: 70px;
    position: relative;
    line-height: 14px;
    padding-top: 5px;
  }
  .ticket-card .head > div:last-child:not(.circle-sold) {
    border-top-right-radius: var(--bs-border-radius-xl);
  }
  .ticket-card .head > div:first-child:not(.circle-sold) {
    border-top-left-radius: var(--bs-border-radius-xl);
  }

  .ticket-card .head {
    width: 100%;
    display: inline-flex;
    position: relative;
  }
  .ticket-card .head .timer img {
    width: 14px;
  }
  .ticket-card .head .flags {
    font-size: 14px;
    font-weight: 700;
  }
  .ticket-card .head .timer span {
    font-weight: 700;
    font-size: 14px;
  }
  .ticket-card .body .flags {
    height: 40px;
    text-align: left;
    position: absolute;
    top: 0rem;
    width: calc(100% - 1rem);
  }
  .ticket-card .body .flags span {
    margin-right: 1.5px;
    margin-left: 1.5px;
    font-size: 12px;
  }
  .ticket-card .body .flags span:nth-child(odd) {
    float: left;
  }
  .ticket-card .body .flags span:nth-child(even) {
    float: right;
  }
  .ticket-card .body {
    background: var(--bs-white);
    overflow: hidden;
    text-align: center;
    position: relative;
    padding-bottom: 0.5rem;
    height: 160px;

    padding-left: 0.5rem;
    padding-right: 0.5rem;
    /*padding-top: 0.5rem;*/

    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-size: 0px 100%, 100% 1.5px, 0px 100%, 100% 0px;
    background-repeat: no-repeat;
    /*background-image: repeating-linear-gradient(0deg, var(--bs-gray-400), var(--bs-gray-400) 5px, transparent 5px, transparent 10px), repeating-linear-gradient(90deg, var(--bs-gray-400), var(--bs-gray-400) 5px, transparent 5px, transparent 10px), repeating-linear-gradient(180deg, var(--bs-gray-400), var(--bs-gray-400) 5px, transparent 5px, transparent 10px), repeating-linear-gradient(270deg, var(--bs-gray-400), var(--bs-gray-400) 5px, transparent 5px, transparent 10px);*/
    /*-o-border-image: repeating-linear-gradient(0deg, var(--bs-gray-400), var(--bs-gray-400) 5px, transparent 5px, transparent 10px);*/
    /*border-image: repeating-linear-gradient(0deg, var(--bs-gray-400), var(--bs-gray-400) 5px, transparent 5px, transparent 10px);*/
  }
  .ticket-card .body.mobile-ticket:after {
    /* background: var(--ps-body-bg); */
  }
  .ticket-card .body.mobile-ticket:before {
    /* background: var(--ps-body-bg); */
  }
  .ticket-card .body img {
    object-fit: contain;
    width: calc(100% - 10px);
    height: 100px;
    overflow: hidden !important;
    transition: 300ms;
  }

  .ticket-card .body img:hover {
    /* width: 120%;
    height: 120%;
    overflow: hidden !important;
    margin-top: -20%;
    margin-left: -15%;
    margin-bottom: -70px !important;
    transition: 300ms; */
  }
  .ticket-card .body div.spot-value span {
    font-weight: 700;
  }
  .ticket-card .body div.spot-value {
    margin: 0;
    font-size: 14px;
    color: var(--bs-gray-600);
  }
  .ticket-card .body p {
    margin: 0;
    font-size: 15px;
    color: var(--bs-gray-600);
  }
  .ticket-card .body h2 {
    font-size: 14px;
    white-space: normal;
    font-weight: 700;
    margin: 0;
    z-index: 99;
  }

input.no-arrows::-webkit-outer-spin-button,
input.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"].no-arrows {
  -moz-appearance: textfield;
}

  .ticket-card .foot .add {
    height: 38px;
    display: flex;
    justify-content: center;
  }
  .ticket-card .foot .add .input i {
    border: 2px solid white;
    border-radius: 50%;
    width: 24px;
    min-width: 24px;
    height: 24px;
    font-weight: 500;
    text-align: center;
    line-height: 18px;
    color: white;
    font-size: 18px;
    font-style: normal;
    cursor: pointer;

    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  .ticket-card .foot .add .input input:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
  }
  .ticket-card .foot .add .input span {
    color: white;
    font-weight: 700;
    display: block;
    font-size: 14px;
    line-height: 20px;
    margin-top: 4px;
    margin-right: 10px;
    width: 80%;
    text-align: center;
  }
  .ticket-card .foot .add .input input {
    width: inherit;
    background: transparent;
    color: white;
    font-weight: 700;
    border: 0;
    text-align: center;
    font-size: 14px;
  }
  .ticket-card .foot .add .input {
    display: flex;
    background: #646BDD;
    border-radius: var(--bs-border-radius-pill);
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    max-height: 38px;
    height: 38px;
    border-color: #646BDD;
    align-items:center;
    width: 100px;
  }
  .ticket-card .foot .add > .price {
    background: #646BDD;
    border-radius: var(--bs-border-radius-pill);
    padding: 0.5rem 0.875rem;
    padding-left: 0;

    color: var(--bs-white);
    font-weight: 700;
    font-size: 14px;

    display: block;

    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }


  .ticket-card .btn-add-to-cart {
    display: flex;
    font-weight: 700;
    height: 38px;
    background-color: #8389E4 !important;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
  }

  .ticket-card .btn-add-to-cart span {
    float: right;

  }

  .ticket-card .btn-add-to-cart:hover {
    /* border-color: #646BDD !important; */
  }

  .ticket-card .foot {
    position: relative;
    background: var(--bs-white);
    padding: 1rem 0.5rem;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  @media (max-width: 968px) {
    #mainWrapper
      > div
      div
      > div
      > div
      > div.pointer
      > div.home-ticket-slider
      > div.slider-container
      > div
      > div.swiper-wrapper
      > div.swiper-slide.swiper-slide-active
      > div
      > div.foot
      > div
      > div.input.w-60
      > i,
    #mainWrapper
      > div:nth-child(1)
      > div
      > div
      > div
      > div.pointer
      > div.home-ticket-slider
      > div.slider-container
      > div
      > div.swiper-wrapper
      > div.swiper-slide.swiper-slide-next
      > div
      > div.foot
      > div
      > div.input.w-60
      > i {
      border: 2px solid var(--bs-green);
      width: 19px;
      min-width: 19px;
      height: 19px;
      line-height: 15px;
      font-size: 15px;
    }

    #mainWrapper
      > div
      > div
      > div
      > div
      > div.pointer
      > div.home-ticket-slider
      > div.slider-container
      > div
      > div.swiper-wrapper
      > div.swiper-slide.swiper-slide-prev
      > div
      > div.head
      > div.timer
      > span,
    #mainWrapper
      > div
      > div
      > div
      > div
      > div.pointer
      > div.home-ticket-slider
      > div.slider-container
      > div
      > div.swiper-wrapper
      > div.swiper-slide.swiper-slide-active
      > div
      > div.head
      > div.timer
      > span,
    #mainWrapper
      > div
      > div
      > div
      > div
      > div.pointer
      > div.home-ticket-slider
      > div.slider-container
      > div
      > div.swiper-wrapper
      > div.swiper-slide.swiper-slide-next
      > div
      > div.head
      > div.timer
      > span {
      font-size: 10px;
      white-space: nowrap;
      padding-right: 2px;
    }
  }
